@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/components/modal";
@import "src/docs/identity/typography";

.#{$prefix}-redirectModal {
  position: relative;
  width: 100%;

  &__questionTitle {
    font-size: $ds-font-size-medium;
  }

  &__into {
    margin: $spacer-m 0;
    font-size: $font-size-base;
  }

  &__input {
    width: 100%;
    padding: 8px 15px;
    border: 1px solid $grey-4;
    outline: none;
  }

  &__input:focus {
    border-color: $blue--dark;
  }

  &__optionsList,
  &__optionsList:focus {
    position: absolute;
    width: 100%;
    margin-top: $spacer-xs;
    outline: none;
    background: $white;
    box-shadow: 0 0 .9rem 0 rgba($black, .2);
    z-index: 1;
  }

  &__option,
  &__option:focus {
    width: 100%;
    min-height: 20px;
    margin-left: 0;
    padding: 8px 15px;
    border: none;
    outline: none;
    background: none;
    color: $grey-2;
    text-align: left;
    box-shadow: none;
  }

  &__option b {
    //fix click event on highlited part of text
    pointer-events: none;
  }

  &__option:hover,
  &__option--selected {
    background-color: $grey-5;
    cursor: pointer;
  }
}
