@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../icons.scss";

.#{$prefix}-icon--filter {
  width: 16px !important;
  height: 12px !important;
  background-image: url($filter-icon--black);
  background-repeat: no-repeat;
}

.#{$prefix}-sidebar__wrapper {
  display: flex;
  align-items: flex-end;
}

.#{$prefix}-sidebar {
  display: none;
  width: 100%;
  margin-top: 0;
  margin-left: auto;
  background-color: $white;

  &--pull-right {
    margin-bottom: $spacer-s;

    @media screen and (min-width: $screen-sm-min) {
      order: 1;
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    display: block;
  }

  .#{$prefix}-mobile__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer-m;
    padding: 12px;
    background-color: $grey-1;

    @media screen and (min-width: $screen-sm-min) {
      display: none;
    }
  }

  &.#{$prefix}-mobile-active {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 $spacer-s 0;
    transition: opacity .15s linear;
    overflow-y: auto;
    z-index: 1050;

    @media screen and (min-width: $screen-sm-min) {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      width: 30%;
      height: unset;
      margin-top: 40px;
      z-index: 1;
    }
  }

  &__close {
    padding-top: $spacer-xxs;
    padding-bottom: $spacer-xxs;
    border-color: $white;
    background-color: $grey-1;
    color: $white;
    font-size: $font-size-base;

    &:hover {
      border-color: $grey-1;
      background-color: $white;
      color: $grey-1;
    }
  }

  &__wrapper-row {
    @media screen and (max-width: $screen-xs-max) {
      flex-wrap: wrap-reverse !important;
    }
  }
}

.#{$prefix}-mobile-filter {
  &__button {
    display: inline-block;
    position: relative;
    margin-left: auto;
    padding: 0 0 0 $spacer;
    border: 1px solid transparent;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    color: $grey-3;
    font-family: $font-family-book;
    font-size: $font-size-base;
    line-height: 1.2;
    text-align: center;
    cursor: pointer;

    &:hover {
      .#{$prefix}-icon--filter {
        background-image: url($filter-icon--blue);
      }

      .#{$prefix}-mobile-filter__text {
        color: $blue;
      }
    }

    &--visibility {
      display: none;

      @media screen and (max-width: $screen-xs-max) {
        display: block;
      }
    }
    .#{$prefix}-icon--browse {
      width: 16px !important;
      height: 12px !important;
    }

    .#{$prefix}-mobile-browse__text {
      color: $blue;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &__text {
    color: $black;
    font-size: 14px;
    cursor: pointer;

    &:visited {
      color: $black;
    }
  }
}
