@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";

.#{$prefix}-answer-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: $spacer-m;
  border: 1px solid $grey-5;
  background-color: $white;

  &__share {
    $_answer-form__share-selector: & !global;

    @include body;

    order: 1;
    width: 100%;
    margin-bottom: $spacer-s;
    color: $grey-2;

    .#{$prefix}-link {
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__tip {
    $_answer-form__tip-selector: & !global;

    @include body;

    order: 0;
  }

  &__tip-heading {
    @include heading-small;

    margin: 0;
    padding-bottom: $spacer-xs;
    border-bottom: 1px solid $grey-3;
    color: $black;
  }

  &__tip-text {
    $_answer-form__tip-text-selector: & !global;

    margin: $spacer-s 0;
    white-space: break-spaces;
  }

  &__tip-links-list {
    margin-bottom: $spacer-s;
    padding: 0;
    list-style: none;
  }

  &__tip-links-item {
    padding: $spacer-xxs 0;
  }

  &__tip-links-link {
    color: $blue;
    text-decoration: none;

    &::after {
      display: inline-block;
      height: $ds-font-size-xxs;
      margin-left: $spacer-xxs;
      font-family: $icon-font-family;
      font-size: $ds-font-size-xxs;
      line-height: 1;
      content: $icon-arrow-right;
    }
  }

  &__form-heading {
    @include heading-small;

    margin: 0 0 $spacer-s;

    .#{$prefix}-link {
      @include heading-small;

      color: $blue;

      &:hover {
        color: $blue--dark;
      }
    }
  }

  &__form-container {
    $_answer-form__form-selector: & !global;

    order: 2;
    width: 100%;
  }

  &__form {
    position: relative;
  }

  &__control {
    $_answer-form__control-selector: & !global;

    margin-bottom: $spacer-s;
    resize: vertical;

    textarea {
      min-height: 200px;
      resize: vertical;
    }

    &--disabled {
      opacity: .61;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__btn-container {
    .#{$prefix}-button + .#{$prefix}-button {
      margin-left: $spacer-s;
    }
  }

  &__disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: .2;
  }

  &__text-length-message {
    @include fine-print;

    margin-bottom: $spacer-s;
  }

  .#{$prefix}-tooltip__wrapper {
    width: 100%;
  }
}

@at-root {
  @include ds-breakpoint(tablets) {
    $ds-answers-form-control-width: 66%;
    #{$_answer-form__share-selector} {
      order: 0;
      padding-top: $spacer-xs;
    }
    #{$_answer-form__form-selector} {
      order: 1;
      width: $ds-answers-form-control-width;
    }
    #{$_answer-form__tip-selector} {
      order: 2;
      width: #{ 100% - $ds-answers-form-control-width };
      padding: 0 $spacer-l 0 $spacer-xl;
    }
    #{$_answer-form__tip-text-selector} {
      line-height: 1.9;
    }
    #{$_answer-form__control-selector} {
      textarea {
        min-height: 300px;
      }
    }
  }
}
