@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

$iconSize: 1.5rem;

.#{$prefix}-accordion-item {
  &:not(:last-of-type) {
    border-bottom: 1px solid $grey-1;
  }

  &__title-wrapper {
    display: flex;
  }

  &__title-actions {
    display: flex;
    align-items: flex-start;
    margin-top: $spacer-m;
    margin-left: auto;
  }

  &__content {
    @include body;

    display: none;
    margin-bottom: $spacer-xs;
    margin-left: calc(#{$iconSize} + #{$spacer-s});
    color: $grey-1;
    font-size: .875rem;

    &--active {
      display: block;
    }

    @media only screen and (max-width: $screen-xs-max) {
      margin-left: 0;
    }
  }

  &__toggle {
    @include heading-xsmall;

    display: flex;
    padding: $spacer-m 0;
    color: $grey-1;
    text-align: left;

    > div {
      display: flex;
    }

    i {
      &::before {
        margin-top: $spacer-xxs;
        margin-right: $spacer-s;
        font-size: $iconSize;
      }
    }

    &:focus {
      outline: auto 5px -webkit-focus-ring-color;

      div:first-child {
        box-shadow: none;
      }
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}
