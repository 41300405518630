@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "src/docs/identity/typography";

.#{$prefix}-attachments-form-container {
  &__heading {
    @include heading-xsmall;
  }

  &__tip {
    @include body;

    margin: $spacer-xs 0;
  }

  &__attachments-container,
  &__images-attachments-container,
  &__files-attachments-container {
    display: flex;
    flex-direction: column;
  }

  &__images-attachments-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__attachment {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    margin-bottom: $spacer-s;
    padding: $spacer-xs;
    border: 1px solid $grey-4;
  }

  &__file-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-decoration: none;
  }

  &__image-preview {
    width: auto;
    max-width: 100px;
    max-height: 70px;
    margin: 0 $spacer-m;
  }

  &__remove-button {
    position: absolute;
    top: calc(50% - 11px); // close icon height
    right: 0;
    margin-top: $spacer-xxs;
    margin-right: $spacer-s;

    i {
      margin: 0 !important;
    }
  }

  &__file-size {
    @include fine-print;

    margin-left: $spacer-xxs;
    overflow-wrap: break-word;
  }

  &__attachment--image {
    flex-direction: column;
    width: 150px;
    margin-right: $spacer-s;
    padding: $spacer-xxs;

    .#{$prefix}-attachments-form-container__remove-button {
      top: 0;
      margin-right: $spacer-xxs;

      i::before {
        font-size: $ds-font-size-xxs;
      }
    }
    .#{$prefix}-attachments-form-container__file-size {
      top: 0;
      margin-left: 0;
    }
    .#{$prefix}-attachments-form-container__file-name {
      display: block;
      overflow-wrap: break-word;
      max-width: 100%;
      text-align: center;
    }
  }
}
