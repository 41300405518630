@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/identity/icons";
@import "~sap-global-ds/src/components/input";
.#{$prefix}-ask-question {
  @import "creation-tip";
  @import "similar-questions";
  @import "../redactor";

  $tagPickerWidth: 87.5%;

  width: 100%;

  &__revisions-select,
  &__title-input,
  &__similar-questions,
  &__question-area,
  &__language-select,
  &__error-message,
  &__sap-tags,
  &__user-tags,
  &__container,
  &__text-hint,
  &__update-summary {
    margin-bottom: $spacer-l;
  }

  &__word-question {
    margin-bottom: $spacer-m;
  }

  &__language-select {
    @include ds-breakpoint(tablets) {
      max-width: 37.5%;
    }
  }

  &__text-hint {
    @include ds-breakpoint(tablets) {
      max-width: $tagPickerWidth;
    }
  }

  &__sap-tags,
  &__user-tags,
  &__update-summary {
    @include ds-breakpoint(tablets) {
      max-width: $tagPickerWidth;
    }
  }

  &__container {
    display: inline;

    .#{$prefix}-ask-question__submit-button {
      margin-right: $spacer-l;
    }
  }

  &__editorial-guideline {
    margin-top: $spacer-l;

    @include ds-breakpoint(tablets) {
      margin-top: 0;
    }
  }
}
