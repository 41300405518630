@import "../../../colors";

$socialRowHeight: 2.5rem;
$socialSideBarWidth: 12rem;

.#{$prefix}-blog-post {
  display: flex;

  &__content-wrapper {
    padding-bottom: $spacer-xl;
  }

  &__content {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow: 1;
    order: 1;
    word-break: break-word;
    min-width: 0; // fix overflow of pre elements
  }

  &__header-wrapper {
    display: flex;
  }

  &__body-wrapper {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $screen-xs-max) {
      flex-direction: column-reverse;
    }
  }

  &__social-side-bar {
    display: flex;
    flex-direction: column;
    order: 0;
    min-width: $socialSideBarWidth;
    margin-bottom: calc(#{$socialRowHeight} * -1);
    padding-top: 10rem;

    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $spacer-l;
      padding: 0;
    }

    .#{$prefix}-sticky-on-scroll {
      max-width: 10rem;

      @media screen and (max-width: $screen-xs-max) {
        max-width: 100%;
      }
    }
  }

  &__right-side-bar {
    @media screen and (max-width: $screen-sm-max) {
      margin-top: $spacer-l;
    }
  }

  &__social-actions {
    display: flex;
    flex-direction: column;
    max-width: 9rem;
    padding-top: $spacer-m;

    @media screen and (max-width: $screen-xs-max) {
      flex-direction: row;
      justify-content: flex-start;
      max-width: 100%;
      padding-top: 0;
    }
  }

  &__social-action {
    justify-content: center;
    margin-bottom: 0;
    margin-left: 0;
    font-size: 1rem;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: $spacer-m;

      @media screen and (max-width: $screen-xs-max) {
        margin-right: $spacer-s;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      padding-right: $spacer-s;
      padding-left: $spacer-s;
    }

    &--follow:not(:disabled) {
      background-color: $green--dark;

      &:hover,
      &:focus {
        background-color: $green--dark1;
      }
    }
  }

  &__author-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__avatar {
    display: flex;
    position: absolute;
    left: 2rem;
    justify-content: center;

    @media screen and (max-width: $screen-sm-max) {
      left: 1.75rem;
    }

    @media screen and (max-width: $screen-xs-max) {
      position: relative;
      left: 0;
    }

    .#{$prefix}-avatar-component {
      width: 8rem;
      height: 8rem;

      @media screen and (max-width: $screen-xs-max) {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
      }
    }
  }

  &__title {
    @include font-size--base-4();

    margin-bottom: $spacer-xs;

    @media screen and (max-width: $screen-sm-max) {
      @include font-size--base-3();
    }
  }

  &__sub-title {
    margin-bottom: $spacer-l;
    color: $grey-2;
  }

  &__author {
    margin-bottom: $spacer-xs;

    a {
      color: $black;
      font-size: 1.125rem;
    }

    &:hover {
      color: $blue;
    }
  }

  &__meta-info {
    margin-bottom: $spacer-m;
    font-size: $font-size-base;

    span {
      color: $grey-2;
    }
  }

  &__read-duration {
    &::before {
      margin: 0 $spacer-xs;
      content: "|";
    }
  }

  &__social-stats-wrapper {
    display: flex;
  }

  &__edit-link {
    margin-left: $spacer-s;
  }

  &__body {
    margin-top: $spacer-l;
    margin-bottom: $spacer-l;
    color: $grey-1;
    font-size: $font-size-base;
    line-height: 1.5;

    img {
      max-width: 100%;
    }
  }

  &__social-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: $socialRowHeight;
    padding-left: $socialSideBarWidth;

    @media screen and (max-width: $screen-xs-max) {
      padding-left: 0;
    }
  }

  &__social-icons {
    > a {
      margin-right: $spacer-s;
    }
  }

  &__alert-moderator-button {
    font-size: 1rem;
  }

  &__alert-moderator-form {
    margin-top: $spacer-l;
    margin-left: $socialSideBarWidth;

    @media screen and (max-width: $screen-xs-max) {
      margin-left: 0;
    }
  }

  &__comment-section {
    background-color: $grey-6;
  }
}
