@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors";

$tooltip-breakpoint: 1340px;

.sb-search-bar__wrapper {
  height: 300px;
  padding: 100px 30px 0 10px;
  background-color: gray;

  @media screen and (min-width: $screen-xs-min) and (max-width: $tooltip-breakpoint - 1) {
    text-align: right;
  }
}

.#{$prefix}-tooltip__search-bar {
  min-width: 290px;
  padding: $spacer-s;
  font-weight: $font-regular;
  text-align: left;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
  z-index: 100;

  &.#{$prefix}-tooltip--right {
    transform: translateY(-45%);

    &::after {
      top: 46%;
      border-width: .675rem .6375rem;
    }
  }

  &-inner {
    padding: 0;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  &--hover {
    margin-left: 24px;

    @media screen and (min-width: $screen-xs-min) and (max-width: $tooltip-breakpoint) {
      margin-left: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  &-inner:focus > &--hover {
    outline: 2px solid $blue--light3;
  }

  @media screen and (min-width: $screen-xs-min) and (max-width: $tooltip-breakpoint) {
    top: calc(100% + 20px);
    right: -3px;
    left: unset;
    transform: unset !important;

    &::after {
      top: unset !important;
      right: 5px;
      bottom: 100%;
      left: unset;
      transform: unset;
      border-width: .675rem .6375rem;
    }
  }

  ul {
    margin: $spacer-xxs 0 0 $spacer-xs;
    padding-left: $spacer-xs;
  }
}
