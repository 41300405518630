@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors";
@import "../../utils/mixins";

.#{$prefix}-select-component {
  @include ds-button-link("&__opener", "&__opener-inner");

  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  -webkit-appearance: none;
  vertical-align: middle;

  &__opener-inner {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
    line-height: $font-size-base;
  }

  &__opener--white {
    color: $white;

    &:hover {
      .#{$prefix}-icon, span, .#{$prefix}-icon--regular {
        color: $blue !important;
      }
    }
  }

  &__icon {
    height: $font-size-base;
    padding-left: $spacer-xs;

    &::before {
      font-size: 1.2rem;
      line-height: $font-size-base;
      content: $icon-arrow-down;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 12px);
    left: -21px !important;
    min-width: 15rem;
    background-color: $white;
    color: $black;
    line-height: $font-size-base;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 0 .9rem 0 rgba($black, .2);
    z-index: 100;

    &-language-selector {
      min-width: 10rem;
    }

    &__inner {
      min-height: 1.5rem;

      &__option {
        display: block;
        width: 100%;
        margin-bottom: $spacer-xxs;
        margin-left: 0;
        padding: $spacer-xs $spacer-s;
        border: none;
        background-color: transparent;
        color: $black;
        font-size: $font-size-base;
        text-align: left;
        text-decoration: none;
        white-space: pre-wrap;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $grey-5;
        }

        &--selected {
          background-color: $grey-5;
        }

        &:first-child {
          margin-top: $spacer-xxs;
        }

        &:active,
        :visited {
          color: $grey-1;
        }
      }
    }
  }

  .#{$prefix}-language-selector__language-code {
    @include font--primary-regular();

    align-self: center;
    margin-left: $spacer-xs;
    color: $white;
  }
  .#{$prefix}-language-selector__icon {
    &::before {
      font-size: 1.3rem;
    }
  }

  .#{$prefix}-language-selector__arrow-icon {
    margin-left: $spacer-xs;
  }

  &__dropdown-opened {
    display: block;
  }

  &__dropdown-option--active {
    background: $grey-5;
  }

  &__icon-opened::before {
    content: $icon-arrow-up;
  }
}

.#{$prefix}-dropdown-button {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: $white;
}

.#{$prefix}-dropdown__ie-opened {
  overflow: visible;
}
