@import "node_modules/sap-global-ds/src/core/core";

.#{$prefix}-answers-redactor {
  &.#{$prefix}-textField--error {
    /* stylelint-disable-next-line selector-class-pattern */
    .redactor_box {
      border-color: $control-error-border-color;
    }
  }

  &__saving-draft {
    @include body;

    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
