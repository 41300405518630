@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-topic-experts__wrapper {
  @media screen and (max-width: $screen-xs-max) {
    margin: 0 $spacer-s $spacer-l;
  }

  .#{$prefix}-topic-experts {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
      display: flex;
      margin: $spacer-s 0 $spacer-m;

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: $spacer-s;
      }

      &-link {
        color: $black;
        font-weight: $font-regular;

        &:hover {
          color: $blue;
          text-decoration: underline;
        }
      }
    }
  }
}
