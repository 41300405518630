@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";

.#{$prefix}-comment-form {
  &__heading {
    margin-top: 0;
    margin-bottom: $spacer-s;
  }

  &__tip {
    margin-bottom: $spacer-xs;
    padding: $spacer-s;
    background-color: $gold--light;
  }

  &__tip-heading {
    @include font--primary-medium;
    @include font-size--base-1;

    color: $gold--dark;
  }

  &__tip-content {
    @include body;

    color: $gold--dark;
  }

  &__message {
    @include fine-print;
  }

  &__error-message {
    margin-bottom: $spacer-xxs;
    color: $red--light3;
    font-size: 12px;
  }

  &__control {
    margin-bottom: $spacer-s;
  }

  &__moderation-checkbox {
    margin-top: $spacer-s;
    margin-bottom: $spacer-s;
  }

  &__buttons-container {
    margin-top: $spacer-xs;
  }

  &__button {
    margin-right: $spacer-s;
  }
}
