@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../identity/typography";
@import "../../../colors.scss";

$tooltip-spacer: 10px;
$loading-indicator-size: 3.25rem;

.#{$prefix}-account-merge {
  &__hero-unit {
    padding: $spacer-m 0;

    &-title {
      @media screen and (max-width: $screen-xs-max) {
        @include font-size--base-3();
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      padding-top: $spacer-l;

      @media screen and (max-width: $screen-sm-max) {
        flex-direction: column;
      }
    }
  }

  &__user {
    display: flex;

    &-avatar {
      margin-right: $spacer-m;

      @media screen and (max-width: $screen-xs-max) {
        margin-right: $spacer;
        margin-left: 0;
      }

      .#{$prefix}-avatar-component {
        width: 70px;
        height: 70px;
      }
    }

    &-email {
      word-break: break-all;
      margin-top: $spacer-s;
    }

    &-name:hover {
      color: $white;
    }
  }

  &__statistics {
    display: flex;

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin: $spacer-l 0 $spacer-m;
    }

    @media screen and (max-width: $screen-xs-max) {
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: $spacer-m;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: $spacer-l;
      white-space: nowrap;

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        &:first-child {
          margin-left: 0;
        }
      }

      @media screen and (max-width: $screen-xs-max) {
        margin: $spacer-s;
      }

      &-inner {
        display: flex;
        align-items: center;
        margin-bottom: $spacer-xs;
      }
    }

    &-icon {
      margin-right: $spacer-xs;

      &::before {
        color: $gold;
      }
    }
  }

  &__content-wrapper {
    flex: 1 0 auto;
    margin: $spacer-l 0 $spacer-xl;

    @media screen and (max-width: $screen-xs-max) {
      margin-top: $spacer;
    }
  }

  &__tabs-container {
    margin: 0 0 auto;
  }

  .#{$prefix}-tab-navigation-item {
    margin-bottom: $spacer-xs;
    font-family: $font-family-regular;

    @media screen and (min-width: $screen-sm-min) {
      @include font-size--base-2();
    }

    &:hover {
      color: $black;
    }
  }

  .#{$prefix}-tab-navigation-menu {
    border-color: $grey-4;

    @media screen and (max-width: $screen-xs-max) {
      margin-top: $spacer-l;

      li:first-child .#{$prefix}-tab-navigation-item {
        margin-left: 0;
      }
    }
  }

  .#{$prefix}-active-tab {
    border-width: 2px;

    .#{$prefix}-tab-navigation-item {
      color: $black;
    }
  }

  &__available-accounts-heading {
    margin-top: $spacer;
    padding-bottom: $spacer-xs;
    border-bottom: 1px solid $grey-4;

    @media screen and (max-width: $screen-xs-max) {
      margin-top: $spacer-l;
    }

    span {
      padding: 0 $spacer-s $spacer-xs;
      border-bottom: 2px solid $gold;

      @media screen and (max-width: $screen-xs-max) {
        padding: 0 0 $spacer-xs;
      }
    }
  }

  // available accounts section styles
  &__available-accounts-subtitle {
    margin: $spacer-m 0;
  }

  &__checkbox-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__role-icon {
    margin-left: $spacer-xs;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: $spacer-s;
    }
  }

  &__checkbox {
    margin-right: $spacer;

    .#{$prefix}-checkbox__label {
      @include body-large;

      font-family: $font-family-regular;
    }

    .#{$prefix}-checkbox__box {
      margin-right: $spacer-s;
    }

    &--select-all {
      margin: $spacer-l 0 $spacer-xs $spacer-xs;
    }
  }

  &__available-accounts-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &--scroll {
      @media screen and (min-width: $screen-md-min) {
        max-height: 1170px;
        overflow-y: scroll;
      }
    }

    li {
      padding: $spacer-m $spacer-xs;
      border-top: 1px solid $grey-4;
    }
  }

  &__account-email {
    margin: $spacer-xs 0 0 $spacer-l;
    word-break: break-all;
  }

  &__account-statistics {
    display: inline-flex;
    flex-wrap: wrap;
    margin-left: $spacer-l;

    div {
      margin-top: $spacer-s;
      margin-right: $spacer-s;
    }
  }

  &__button-merge {
    margin: $spacer-l 0 0;
  }

  &__disabled-account {
    display: flex;
    align-items: center;

    &-label {
      margin-right: $spacer-s;
      font-size: $ds-font-size-medium;

      @media screen and (max-width: $screen-xs-max) {
        margin-left: $spacer-l;
      }
    }

    &-icon {
      color: $red--dark !important;

      &:hover {
        cursor: pointer;
      }
    }

    &-tooltip {
      &::after {
        top: $tooltip-spacer;
      }

      @media screen and (max-width: $screen-sm-max) {
        top: calc(100% + 10px);
        right: -$tooltip-spacer;
        left: unset;

        &::after {
          top: -$tooltip-spacer;
          right: 12px;
          bottom: 100%;
          transform: rotate(0deg);
        }
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $spacer-l;

    > div {
      @media screen and (max-width: $screen-xs-max) {
        width: 70%;
      }
    }

    &-indicator {
      width: $loading-indicator-size !important;
      height: $loading-indicator-size !important;
      margin-right: $spacer-m;
    }

    &-text {
      margin: auto;
      color: $gold--dark3;

      @media screen and (max-width: $screen-xs-max) {
        margin: 0;

        &:last-of-type {
          @include font-size--base-2();
        }
      }
    }
  }

  // merged accounts section styles
  &__merged-accounts-subtitle {
    margin: $spacer-l 0 $spacer-m;
  }

  &__list {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    &-item {
      display: flex;
      justify-content: space-between;
      padding: $spacer-m $spacer-xs;
      border-top: 1px solid $grey-4;

      @media screen and (max-width: $screen-sm-max) {
        flex-direction: column;
      }

      &-login {
        margin-bottom: $spacer-s;
      }

      &-email {
        word-break: break-all;
      }
    }
  }

  &__status {
    margin-top: $spacer-xs;
  }

  &__info-container {
    margin: auto;
  }

  &__info-message {
    margin: 0 0 $spacer-m;
    font-size: $ds-font-size-medium;

    @media screen and (max-width: $screen-sm-max) {
      margin: 0 0 $spacer-m;
    }
  }
}

.#{$prefix}-primary-account {
  border-top: 1px solid $grey-4;

  &__title {
    margin: $spacer 0 $spacer-xs;
  }

  &__warning {
    margin: $spacer-m 0 $spacer-l;

    .#{$prefix}-message__content {
      color: $gold--dark2;
      font-size: $font-size-base;

      .#{$prefix}-link--blue:not(:hover) {
        color: $blue;
      }
    }
  }

  &__account-id {
    @include font-size--base-3();

    display: inline-block;
    margin: $spacer 0 $spacer-s;
  }

  &__email {
    margin-bottom: $spacer-m;
    color: $grey-0;
    word-break: break-all;
  }

  &__info-text {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }
}
