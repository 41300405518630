@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors.scss";

.#{$prefix}-community-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $spacer-xxl;
  margin-bottom: $spacer-xl;
  color: $black;
  font-family: $font-family-book;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  font-stretch: normal;

  &__headline {
    margin-bottom: $spacer-l;
    color: $black;
    font-size: 31px;
    text-align: center;

    @media only screen and (max-width: $screen-sm-min - 1) {
      margin-bottom: $spacer-m;
      font-size: 24px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $screen-md-min - 1) {
      flex-direction: column;
    }
  }

  &__blogs {
    width: 55%;

    @media only screen and (max-width: $screen-md-min - 1) {
      width: 100%;
    }
  }

  &__questions {
    width: 38%;

    @media only screen and (max-width: $screen-md-min - 1) {
      width: 100%;
    }
  }
}
