@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";
@import "modals";

$follow-button-width: 117px;

.#{$prefix}-question-detail {
  @import "question";
  @import "rss-dropdown";
  @import "comments/styles";
  @import "answers/styles";
  @import "vote";
  @import "user";
  @import "../redactor";

  &__search-container {
    $_question-detail__search-container-selector: & !global;

    margin-top: $spacer-l;
    margin-bottom: $spacer-l;
  }

  &__question-container {
    $_question-detail__question-container-selector: & !global;

    margin-bottom: $spacer-l;
  }

  &__answer-container {
    $_question-detail__answers-container-selector: & !global;

    padding-top: $spacer-s;
    padding-bottom: $spacer-xl;
    background-color: $grey-6;
  }

  &__answer-form {
    margin-top: $spacer-l;
  }

  &__search {
    $ds-search-height: 45px;

    @import "src/docs/basics/search-input/search-input";
  }
}

.#{$prefix}-bookmark-container {
  position: relative;
  top: -8px;
}

.#{$prefix}-question-message {
  &--alert {
    margin-bottom: $spacer-m;
  }
}

.#{$prefix}-content {
  &__message {
    margin-bottom: $spacer-s;

    .ds-action-button {
      padding: 0;
      border: 0;
      background: none;
      color: $blue;
      cursor: pointer;
    }
  }

  &--deleted {
    .ds-answer__content,
    .ds-answer__author .ds-user__name,
    .ds-answer__posted,
    .ds-comment__content,
    .ds-comment__author .ds-user__name,
    .ds-comment__date,
    .ds-comment__reply-name {
      color: $grey-3;
      font-style: italic;
    }
  }
}

@include ds-breakpoint(mobile) {
  #{$_question-detail__question-container-selector} {
    min-height: 280px; //to fit follow container when question content is small
  }
  #{$_user__gravatar-img-selector} {
    width: 60px;
    height: 60px;
  }
}

@include ds-breakpoint(tablets) {
  #{$_question-detail__search-container-selector} {
    margin-top: $spacer-xl;
    margin-bottom: $spacer-m;
  }
  #{$_question-detail__answers-container-selector} {
    padding-top: $spacer-l;
  }
}
