.#{$prefix}-home-page {
  &__title-block {
    display: grid;
    margin-bottom: $spacer-l;
    grid-template-columns: 1fr min-content 1fr;

    @media screen and (max-width: $screen-xs-max) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    grid-column-start: 2;
    justify-self: center;
    margin: 0;
    white-space: nowrap;

    @media screen and (max-width: $screen-md-max) {
      justify-self: start;
      grid-column-start: 1;
    }

    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $spacer-s;
      font-size: 1.5rem !important;
    }
  }

  &__subscribe-to-newsletter {
    justify-self: end;
    grid-column-start: 3;
  }
}
