@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/components/radio-group";

$radio-size: 19px;

.#{$prefix}-radio__toggle {
  width: $radio-size;
  min-width: $radio-size;
  height: $radio-size;
  min-height: $radio-size;

  &::after {
    width: .4rem !important;
    height: .4rem !important;
  }
}

.#{$prefix}-radio__label {
  display: inline-flex;
  flex-direction: column;
  padding-top: 1px;
  cursor: default;
}

.#{$prefix}-radio__control {
  &:not(:disabled) ~ .#{$prefix}-radio__label:hover {
    cursor: pointer;
  }
}
