.#{$prefix}-blog-post-form {
  margin-top: $spacer-s;
  padding-top: $spacer-m;
  border-top: 1px solid $grey-2;

  &__hint {
    display: block;
    margin-bottom: $spacer-m;
    color: $grey-1;

    &--error {
      color: $red;
    }
  }

  > * {
    margin-bottom: $spacer-m;
  }

  &__textarea {
    min-width: 100%;

    &--with-hint {
      margin-bottom: $spacer-s;
    }
  }

  &__buttons-container {
    margin-top: $spacer-m;
    margin-bottom: 0;

    > button {
      margin-right: $spacer-m;
    }
  }
}
