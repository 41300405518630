@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../identity/typography";

.#{$prefix}-navigation-wrapper {
  margin-bottom: $spacer-l;

  @media screen and (max-width: $screen-xs-max) {
    margin: 0 $spacer-s $spacer-l;
  }

  &__header {
    display: block;
    margin-bottom: $spacer-s;
    padding-bottom: $spacer-xs;
    border-bottom: 1px solid $grey-3;
    font-size: $font-size-base;
  }

  &__content {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }
}

.#{$prefix}-event {
  margin-bottom: $spacer-m;

  &__title {
    margin-bottom: $spacer-xxs;
  }

  &__info {
    margin-bottom: $spacer-xs;
  }

  &__date {
    font-size: $ds-font-size-xxs;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
  }
}

.#{$prefix}-page-link {
  &--space {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $spacer-xs;

    .#{$prefix}-icon {
      cursor: pointer;
    }
  }

  &__list {
    list-style-type: none;
    padding-left: $spacer-s;
  }
}

.#{$prefix}-list-collapsed {
  display: none;
}
