@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";

.#{$prefix}-ask-question-tip {
  $_answer-form__selector: & !global;

  @include body;

  order: 0;

  &__heading {
    @include heading-small;

    margin: 0;
    padding-bottom: $spacer-xs;
    border-bottom: 1px solid $grey-3;
    color: $black;
  }

  &__text {
    $_answer-form__text-selector: & !global;

    margin: $spacer-s 0;
  }

  &__links-list {
    margin-bottom: $spacer-s;
    padding: 0;
    list-style: none;
  }

  &__links-item {
    padding: $spacer-xxs 0;
  }

  &__links-link {
    color: $blue;
    text-decoration: none;

    &::after {
      display: inline-block;
      height: $ds-font-size-xxs;
      margin-left: $spacer-xxs;
      font-family: $icon-font-family;
      font-size: $ds-font-size-xxs;
      line-height: 1;
      content: $icon-arrow-right;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
