@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";

.#{$prefix}-vote {
  $ds-vote-width: 32px;
  $ds-vote-height: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__btn {
    $ds-vote-btn-color: $grey-3;
    $ds-vote-btn-hover-color: $grey-2;
    $ds-vote-btn-voted-color: $blue;
    $ds-vote-btn-hover-voted-color: $blue--dark;

    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    outline: none;
    background: transparent;
    font-size: 0;
    cursor: pointer;

    &--up {
      margin-bottom: $spacer-xxs;
      border-top: none;
      border-right: $ds-vote-width/2 solid transparent;
      border-bottom: $ds-vote-height solid $ds-vote-btn-color;
      border-left: $ds-vote-width/2 solid transparent;

      &:hover,
      &:focus {
        border-bottom: $ds-vote-height solid $ds-vote-btn-hover-color;
      }
    }

    &--down {
      margin-top: $spacer-xxs;
      border-top: $ds-vote-height solid $ds-vote-btn-color;
      border-right: $ds-vote-width/2 solid transparent;
      border-bottom: none;
      border-left: $ds-vote-width/2 solid transparent;

      &:hover,
      &:focus {
        border-top: $ds-vote-height solid $ds-vote-btn-hover-color;
      }
    }

    &--voted {
      &.#{$prefix}-vote__btn--up {
        border-bottom: $ds-vote-height solid $ds-vote-btn-voted-color;

        &:hover,
        &:focus {
          border-bottom: $ds-vote-height solid $ds-vote-btn-hover-voted-color;
        }
      }
      &.#{$prefix}-vote__btn--down {
        border-top: $ds-vote-height solid $ds-vote-btn-voted-color;

        &:hover,
        &:focus {
          border-top: $ds-vote-height solid $ds-vote-btn-hover-voted-color;
        }
      }
    }

    &--disabled {
      opacity: .5;
      pointer-events: none;
    }
  }

  &__number {
    @include body-medium;

    display: inline-block;
    width: $ds-vote-width;
    color: $grey-3;
    font-family: $font-family-medium;
    text-align: center;
  }
}
