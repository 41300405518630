@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/core/utilities";
@import "~sap-global-ds/src/components/buttons";
@import "./src/colors";
@import "../../utils/mixins";

.#{$prefix}-button {
  &--primary {
    color: $white !important;
  }

  &--action {
    background-color: $green--dark !important;
    color: $white !important;

    &:hover,
    &:focus {
      border-color: $green--dark !important;
      background-color: $green--dark1 !important;
    }
  }

  &--action-secondary {
    border-color: $green--dark !important;
    background-color: $white !important;
    color: $green--dark !important;

    &:hover,
    &:focus {
      background-color: $green--dark !important;
      color: $white !important;
    }
  }

  &--disabled {
    opacity: .6;
    pointer-events: none;
  }

  &--link {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include ds-button-link("&--link", "&__inner-container");
}
