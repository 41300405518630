@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-action-bar {
  display: flex;
  align-items: center;
  height: 40px;

  a:hover {
    color: $blue;
    text-decoration: none;
  }

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    width: 100%;
  }

  .#{$prefix}-icon--arrow-left {
    display: none;
    width: 1.5rem;
  }

  &__button-prev::before,
  &__button-next::before {
    color: $white;
    font-size: 1.3rem;
  }

  &-button {
    display: flex;
    justify-content: center;
    width: 8.5rem;
    height: 40px;
    margin: 0;
    font-family: $font-family-sap;
    font-weight: $font-regular;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      padding: 0;
      font-size: $font-size-base;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    line-height: 1rem;
    text-decoration: none;
    white-space: nowrap;

    &-text {
      padding: 0 $spacer-m;

      &:hover {
        color: $blue;
      }
    }

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      &-text {
        font-size: $font-size-base;
        line-height: 14px;
      }
    }
  }

  &-border {
    align-self: center;
    margin-bottom: $gutter-xxs;
    color: $white;
    font-size: 22px;
    font-weight: $font-book;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      font-size: 15px;
    }
  }

  &-dropdown {
    margin: 0;
    padding: 1px $spacer-m 0 $spacer-m;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      font-size: $font-size-base;

      .ds-icon::before {
        font-size: 1rem;
      }
    }

    &:hover {
      i,
      span {
        color: $blue;
      }
    }
    .#{$prefix}-select-component__dropdown {
      @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        top: calc(100%);
      }

      @media only screen and (min-width: $screen-xs-min + 1) and (max-width: $screen-sm-max) {
        right: 0;
        left: unset;
      }

      @media only screen and (min-width: $screen-md-min) {
        left: unset;
      }
    }
    .#{$prefix}-select-component__opener-inner {
      span,
      i {
        color: white;
      }
    }
  }

  .#{$prefix}-icon--arrow-right {
    display: none;
    width: 1.5rem;
    margin-right: 0;
    margin-left: auto;
  }

  &__primary-link {
    height: 40px;

    .#{$prefix}-action-bar-link-text {
      padding: 0;

      &:hover {
        color: $white;
      }
    }
  }

  &__primary-dropdown {
    height: 40px;
    padding: 0;

    .#{$prefix}-select-component__opener {
      width: 100%;
      height: 100%;
    }

    .#{$prefix}-select-component__opener:focus > .#{$prefix}-select-component__opener-inner {
      background-color: $blue--dark;
      box-shadow: none;
    }

    .#{$prefix}-select-component__opener--white {
      width: 100%;
      height: 100%;
      padding: 1px 1.25rem 0 1.25rem;

      &:hover span,
      &:hover i {
        color: $white !important;
      }
    }
  }
}

@media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
  .#{$prefix}-display--none {
    display: none !important;
  }

  .#{$prefix}-display--flex {
    display: flex !important;
  }
}

.#{$prefix}-no-border {
  border: none;
}
