@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../identity/typography";

.#{$prefix}-merge-confirmation-modal,
.#{$prefix}-completion-modal,
.#{$prefix}-primary-account-modal {
  &__accounts {
    margin-bottom: $spacer-m;
  }

  &__message {
    margin-bottom: 0;
    font-size: $ds-font-size-medium;
  }

  &__text,
  &__accounts {
    font-size: $ds-font-size-medium;
    line-height: 28px;
  }

  .#{$prefix}-modal__box {
    height: auto;
    min-height: unset;

    @media screen and (min-width: $screen-sm-min) {
      flex-basis: 90%;
      max-width: 530px;
    }
  }

  .#{$prefix}-modal__box__header__heading {
    font-size: 27px;
  }
}

.#{$prefix}-primary-account-modal {
  .#{$prefix}-modal__box {
    @media screen and (min-width: $screen-sm-min) {
      max-width: 500px;
    }

    &__content {
      margin-top: 0;
    }
  }

  .#{$prefix}-input {
    &__control {
      height: 40px !important;
      border-color: $grey-2 !important;
    }

    &__placeholder {
      color: $grey-3;
      font-family: $font-family-book;
      font-style: unset;
    }
  }

  .#{$prefix}-filter {
    &__input {
      margin-bottom: 0 !important;
    }

    &__list {
      margin: $spacer-m 0 0;

      &-container {
        min-height: 325px;
        max-height: 325px;
        padding: $spacer-s $spacer-s 0;
        border: 1px solid $grey-4;
        border-top: unset;
      }
    }
  }

  .#{$prefix}-radio {
    &__label span {
      font-family: $font-family-book;

      &:nth-child(2) {
        margin-top: $spacer-xxs;
        word-break: break-all;
      }
    }

    &__toggle {
      margin-top: $spacer-xxs;
    }
  }
}
