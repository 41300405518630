@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-home-statistics {
  display: flex;

  &__label {
    white-space: nowrap;
  }

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    /* stylelint-disable-next-line */
    display: -ms-grid;
    display: grid;
    width: 100%;
    padding-top: $spacer-s;
    grid-template-columns: 50% 50%;
    -ms-grid-columns: 50% 50%;
    -ms-grid-rows: 50% 50%;

    &__label {
      font-size: $font-size-base;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacer-xs 0;
    padding-right: $spacer-xl;

    &:last-child {
      padding-right: 0;
    }

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      padding: $spacer-m;
      padding-right: $spacer-l;

      &:last-child {
        padding-right: $spacer-m;
      }
    }

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      padding: 0;
      padding-bottom: $spacer-m;

      &:last-child {
        padding-right: 0;
      }
    }

    &--inline {
      display: flex;
      align-items: center;
      padding-bottom: $spacer-xxs;
    }
  }

  &__number {
    padding-left: $spacer-s;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      font-size: $font-size-base;
    }
  }

  &__icon {
    width: 1.2rem !important;
    height: 1.2rem !important;
    font-size: 1.2rem !important;
  }
}

.#{$prefix}-ie-grid-item {
  &-1 {
    -ms-grid-column: 2;
  }

  &-2 {
    -ms-grid-row: 2;
  }

  &-3 {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
}
