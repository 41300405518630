@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/components/modal";
@import "src/docs/identity/typography";

.#{$prefix}-fileUploadModal {
  position: relative;
  width: 100%;

  &__input {
    width: 0;

    &:focus + .ds-fileUploadModal__desc {
      background-color: $blue--dark;
      color: $white;
    }
  }

  &__desc {
    margin-bottom: $spacer-s;
  }

  &__fileName {
    position: relative;
    top: 10px;
    margin-left: 10px;
    color: #727272;
  }

  &__error {
    margin-top: $spacer-s;
  }

  &__copyrights {
    margin-top: $spacer-s;
  }
}
