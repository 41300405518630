.#{$prefix}-resource-page {
  &__hero-unit {
    padding-top: $spacer-m;
  }

  &__paragraphs-wrapper {
    > div {
      margin-bottom: $spacer-xl;
    }
  }
}
