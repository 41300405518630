@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors";
@import "../../../icons.scss";

.#{$prefix}-tag-picker__container * {
  box-sizing: border-box;
}

.#{$prefix}-tag-picker__input {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-bottom: $control-margin-bottom;
  padding: $control-y-padding $control-x-padding;
  border: 0;
  border-radius: 0;
  background-color: $grey-5;
  font-size: 14px;
  vertical-align: baseline;

  &::after {
    display: inline-block;
    position: relative;
    top: -.15rem;
    width: 0;
    height: 0;
    margin-left: .4rem;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
  }
}

.#{$prefix}-tag-picker {
  position: relative;
  font-size: $font-size-base;
}

.#{$prefix}-tag-picker__container {
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  float: none;
  border: 1px solid $grey-5;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-color: $white;
  list-style: none;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
  -o-box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
  -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
  box-shadow: 0 3px 3px rgba(0, 0, 0, .3);
  overflow: auto;
  webkit-appearance: none;
  z-index: 100;
}

.#{$prefix}-tag-picker__container--active {
  display: block;
}

.#{$prefix}-tag-picker__no-result-message,
.#{$prefix}-tag-picker__no-items-message {
  padding: $control-x-padding * 1.25;
  border-color: $blue--light1;
  background-color: $blue--light;
  color: $blue--dark1;
}

.#{$prefix}-tag-picker__search-input {
  display: inline-block;
  max-width: 100%;
  padding: $control-x-padding * .5 0 $control-x-padding * .25;
  border: 0;
  border-radius: 0;
  vertical-align: middle;

  &:focus {
    outline: none;
  }

  &::-ms-clear {
    display: none;
  }

  &[hidden] {
    display: none;
  }
}

.#{$prefix}-tag-picker__input-container {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 40px;
  padding: $control-x-padding $control-x-padding * 1.5;
  border: 1px solid $grey-3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: $white;
  background-image: none;
  color: $grey-3;
  font-family: $font-family-regular;
  line-height: 1.47;
  .#{$prefix}-choices__list_multiple {
    .#{$prefix}-choices__item {
      display: inline-block;
      margin-right: $gutter-xs;
      margin-bottom: 0;
      padding: $control-x-padding * .5;
      border: 1px solid $grey-4;
      border-radius: 0;
      background-color: $grey-5;
      color: $grey-1;
      font-family: $font-family-regular;
      vertical-align: middle;
      word-break: break-all;
    }
    .#{$prefix}-choices__inner {
      display: inline-block;
      width: 100%;
      min-height: 44px;
      padding: 7.5px 7.5px 3.75px;
      border: 1px solid $grey-4;
      border-radius: 2.5px;
      background-color: $grey-5;
      overflow: hidden;
      vertical-align: top;
    }
  }

  &_empty {
    border: none;
  }
  .#{$prefix}-textField--error & {
    border-color: $red--dark1;
  }

  .is-open &,
  .is-focus & {
    border-color: $control-border-color-active;
  }
}

.#{$prefix}-choices__list_multiple
.#{$prefix}-choices__item
.#{$prefix}-choices__button,
.#{$prefix}-choices__list_single
.#{$prefix}-choices__item
.#{$prefix}-choices__button {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  margin-left: $gutter-xxs;
  padding: 0;
  border: none;
  background-color: transparent;
  background-size: 9px;
  text-indent: -9999px;
  cursor: pointer;
  vertical-align: inherit;
}

.#{$prefix}-choices__list_multiple
.#{$prefix}-choices__item
.#{$prefix}-icon-close,
.#{$prefix}-choices__list_single
.#{$prefix}-choices__item
.#{$prefix}-icon-close {
  background-image: url($icon-close);
  background-repeat: no-repeat;
  background-position: 2.8px 2.8px;

  &:hover {
    background-image: url($icon-close--blue);
  }
}

.#{$prefix}-choices__item.choices__item--selectable {
  color: $grey-3;
  font-family: $font-family-book;
  font-size: 14px;
}

.#{$prefix}-tag-picker-list.choices__list--single div[aria-selected='true'],
.#{$prefix}-tag-picker-primary .#{$prefix}-tag-picker-item_selected {
  display: inline-block;
  margin-right: $gutter-xs;
  margin-bottom: 0;
  padding: $control-x-padding * .5 $control-x-padding * 1.25;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: $blue--light;
  background-color: $blue--light;
  color: $grey-1;
  font-family: $font-family-regular;
  vertical-align: middle;
  word-break: break-all;
  border-image: initial;

  .#{$prefix}-choices__button {
    border-color: $blue--light;
    background-color: $blue--light;
  }
}

input[type='text'].#{$prefix}-tag-picker__search-input.choices__input--cloned {
  width: 100%;
  height: auto;
  margin-bottom: 0;
  padding: 0 !important;
  border: 0 none;
  color: $black;
  font-family: $font-family-regular;
  font-size: 14px;
  line-height: normal;
  box-shadow: none;

  &::placeholder {
    opacity: 1;
    color: $grey-2;
    font-size: 14px;
  }

  &:input-placeholder {
    opacity: 1;
    // IE11
    color: $grey-2;
  }
}

.#{$prefix}-choices__item.#{$prefix}-choices__list_single {
  color: $grey-3;
  font-family: $font-family-regular;
}

.#{$prefix}-tag-picker-list.choices__list--single
div[aria-selected='true'].choices__placeholder {
  display: inline-block;
  margin-bottom: 0;
  padding: $control-x-padding;
  padding: 0;
  border-width: 0;
  border-style: none;
  border-radius: 0;
  border-color: transparent;
  background-color: transparent;
  color: $grey-3;
  font-family: $font-family-regular;
  vertical-align: middle;
  word-break: break-all;
  -o-border-image: none;
  border-image: none;
}

.#{$prefix}-tag-picker__input--selected {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: $gutter-xxs;
  padding: 5px 10px;
  color: $grey-1;
  font-family: $font-family-regular;
}

.#{$prefix}-tag-picker-list {
  display: block;
  position: relative;
  width: 100%;
  max-height: 350px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  vertical-align: middle;
}

.#{$prefix}-choices__list_multiple,
.#{$prefix}-choices__list_single {
  display: inline;
}

.#{$prefix}-tag-picker-list-item__content {
  display: table-cell;
  vertical-align: top;
}

.#{$prefix}-tag-picker-list-item {
  display: table;
  width: 100%;
  padding: $control-x-padding 0 $control-x-padding .9375rem;
  border-bottom: 1px solid $grey-5;
  color: $grey-1;
  cursor: pointer;

  &_highlighted {
    background-color: $grey-5;
    color: $grey-1;
    font-size: 14px;
    text-decoration: none;
    cursor: unset;

    a {
      color: $grey-2;
      text-decoration: none;
    }

    span {
      color: $grey-2;
      text-decoration: none;
    }
  }

  &_disabled {
    display: none;
  }

  &_hidden {
    display: none;
  }
}

.#{$prefix}-tag-picker-list-item:last-child {
  border-bottom: 0 none;
}

.#{$prefix}-tag-picker-list-item__avatar-container {
  display: inline-block;
  position: relative;
}

.#{$prefix}-tag-picker-list-item__title {
  color: $grey-1;
  font-family: $font-family-regular;
  line-height: 1.47;
  word-wrap: break-word;
  word-break: break-all;
}

.#{$prefix}-tag-picker-list-item__path {
  color: $grey-2;
  font-family: $font-family-regular;
  font-weight: $font-regular;
  line-height: 1.47;
  word-wrap: break-word;
  word-break: break-all;
}

.#{$prefix}-tag-picker-list__toolbar {
  display: table;
  width: 100%;
  padding: $control-x-padding * 1.25;
  border-bottom: 1px solid $grey-5;
  text-align: right;
}

.#{$prefix}-tag-picker-list__close-button {
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  padding: 0;
  float: right;
  border: 2px solid $grey-3;
  border-radius: 1000px;
  background: 0;
  opacity: 1;
  color: $grey-3;
  font-size: 18px;
  font-weight: $font-regular;
  line-height: .8;
  text-shadow: 0 1px 0 $white;
  cursor: pointer;
}

.#{$prefix}-tag-picker-list__close-button:hover {
  background: $grey-5;
  color: $grey-2;
}

.#{$prefix}-tag-picker-list-item__notice_error {
  background-color: $red--light;
  color: $red--dark1;
}

.#{$prefix}-tag-picker__placeholder {
  $_placeholder-selector: & !global;

  @include font--primary-book();

  position: absolute;
  top: 0;
  left: .5rem;
  margin: 0;
  padding-right: $control-x-padding * .5;
  padding-left: $control-x-padding * .5;
  transform: translateY(-50%);
  background: $white;
  color: $grey-1;
  font-size: $font-size-base;
  pointer-events: none;

  .#{$prefix}-textField--filled:not(.#{$prefix}-textField--error) & {
    color: $control-active-color;
  }
}

.#{$prefix}-tag-picker.is-open + .#{$prefix}-tag-picker__placeholder {
  color: $control-active-color;
}

.#{$prefix}-label--error {
  border-color: $red--dark1;
  color: $red--dark1;
}

.#{$prefix}-tag-picker__group-heading {
  display: block;
  padding: $gutter-s;
  border-bottom: 1px solid $grey-5;
  background-color: $white;
  color: $grey-2;
  font-family: $font-family-regular;
  font-size: 14px;
}

.#{$prefix}-followed-text {
  margin-bottom: $gutter-s;
  font-size: 14px;
}

.#{$prefix}-tag-picker__hint-message {
  @include font--primary-regular;
  @include font-size--base-1;

  margin-bottom: $spacer-m;
  color: $grey-2;
  .#{$prefix}-link {
    color: $blue;
  }
}
