@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";

.#{$prefix}-comments-list {
  &__footer {
    $_comments-list__footer-selector: & !global;

    @include body;

    display: flex;
    justify-content: space-between;
    padding-right: $spacer-xs;

    &-tooltip {
      @media screen and (max-width: $screen-sm-min - 1) {
        display: none !important;
      }
    }

    > * {
      margin: $spacer-m 0;
    }
  }

  &__show-all {
    @include body;

    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    color: $blue;
    text-align: center;
    cursor: pointer;

    i {
      margin-left: $spacer-s;
      outline: none;
      cursor: pointer;
      vertical-align: middle;
    }
  }

  &__comments-locked {
    display: inline-flex;
    align-items: center;
    color: $blue;
  }

  &__add-new {
    display: inline-block;
    color: $grey-2;

    &:hover {
      color: $blue;
    }
  }

  &__comments-counter {
    $_comments-list__comments-counter-selector: & !global;

    @include body;

    display: inline-flex;
    align-items: center;
    color: $grey-3;

    .#{$prefix}-icon--regular {
      margin: 0 $spacer-s 0 0;

      &::before {
        font-size: 1.2rem;
      }
    }
  }

  &__comments-container {
    $_comments-list__comments-container-selector: & !global;
  }

  &__comments-form {
    margin-top: $spacer-s;
    margin-bottom: $spacer-m;
    margin-left: $spacer-m;
  }

  .#{$prefix}-comment + .#{$prefix}-comment {
    margin-top: $spacer-s;
  }
}

@at-root {
  @include ds-breakpoint(tablets) {
    #{$_comments-list__comments-container-selector},
    #{$_comments-list__footer-selector} {
      margin-left: $spacer-l;
    }
  }
}
