@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/components/badges";
@import "../../../colors";

.#{$prefix}-selectedFilters {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-top: $gutter-xs;
  padding-bottom: $gutter-l;

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    flex-flow: column wrap;
  }

  .#{$prefix}-badge {
    border: .0625rem solid $grey-4;
    font-family: $font-family-regular;
    font-size: .875rem;
    font-weight: 300;
    letter-spacing: normal;

    &:visited {
      background-color: $grey-6;
      color: $grey-1;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $grey-5;
    }
  }

  .#{$prefix}-label-text {
    margin-top: .1rem;
    color: $grey-1;

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}

.#{$prefix}-selectedFilters__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: calc(100% - #{$gutter-m});

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    max-width: 100%;
  }
}

.#{$prefix}-selectedFilter__item {
  align-items: center;
  max-width: 100%;
  margin: $gutter-xxs $gutter-s $gutter-xxs 0;
  background-color: $grey-6;
  text-transform: none;
  cursor: pointer;

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
    .#{$prefix}-label-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .#{$prefix}-icon--clear {
    width: 1rem;
    height: 1rem;
    margin-left: $gutter-xs;
    color: $grey-3;
    font-size: 1rem;
  }
}

.#{$prefix}-clearFilters__wrapper {
  @media only screen and (min-width: $screen-sm-min) {
    align-self: flex-end;
    margin-left: auto;
  }
}

.#{$prefix}-clearFilters {
  margin: $gutter-xxs 0 $gutter-xxs 0;
  background-color: $white;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  cursor: pointer;
}

.#{$prefix}-collapse-selectedFilters {
  display: none;
}

.#{$prefix}-selectedFilters-separator {
  border-bottom: .0625rem solid $grey-4;
}
