@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";

.#{$prefix}-rss-dropdown {
  $icon-rss-encoded-svg-white: "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20d%3D%22M1.3%207.3C.6%207.3%200%207.9%200%208.6c0%20.7.6%201.3%201.3%201.3s1.3-.6%201.3-1.3c.1-.7-.5-1.3-1.3-1.3zM0%203.4v1.9c1.2%200%202.4.5%203.3%201.4.9.9%201.4%202.1%201.4%203.3h1.9c0-3.6-3-6.6-6.6-6.6zM0%200v1.9c4.5%200%208.1%203.6%208.1%208.1H10C10%204.5%205.5%200%200%200z%22%20fill%3D%22%23ffffff%22/%3E%3C/svg%3E";

  position: relative;

  &__opener {
    width: 100%;
    .#{$prefix}-icon-encoded--rss,
    .#{$prefix}-icon-encoded--rss:before {
      width: 14px;
      height: 20px;
    }

    &:hover,
    &:focus {
      .#{$prefix}-icon-encoded--rss {
        background-image: url($icon-rss-encoded-svg-white);
      }
    }
  }

  &__popup {
    display: none;
  }

  &__checkbox {
    margin-bottom: $spacer-s;
  }

  &__link-container {
    text-align: center;
  }

  &--open {
    .#{$prefix}-rss-dropdown__popup {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 12rem;
      padding: $spacer-m;
      border: 1px solid $grey-2;
      background-color: $white;
      z-index: 1;
    }
  }
}
