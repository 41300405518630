@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.sb-wrapper {
  padding: 0;

  @media only screen and (min-width: $screen-md-min) {
    padding: $spacer-m;
    background-color: $grey-2;
  }
}

.#{$prefix}-home-hero-unit {
  /* stylelint-disable-next-line */
  display: -ms-grid;
  display: grid;
  padding-bottom: $spacer-l;
  grid-template-columns: 55% 40%;
  background-color: $grey-1;
  -ms-grid-columns: 55% 45%;
  grid-column-gap: 5%;

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    display: block;
    padding: $spacer-l $spacer-s $spacer-s $spacer-s;
  }

  @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
    padding: $gutter-l $gutter-s $spacer-l $gutter-s;
    grid-template-columns: 50% 50%;
    -ms-grid-columns: 50% 50%;
    grid-column-gap: 0;
  }

  &__main-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $gutter-l;

    &__without-widgets {
      width: 50%;
    }

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      width: 100%;
      margin-bottom: 0;
      grid-column-start: 1;
      grid-column-end: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
    }

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__welcome-text {
    display: block;
    flex-flow: column;
    font-size: 32px;
    text-align: center;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      display: flex;
    }
  }

  &__text {
    margin-top: $spacer-m;
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      margin-bottom: $spacer-m;
      text-align: left;
    }
  }

  &__white-item {
    color: $white;
  }

  &__gold-item {
    color: $gold;
  }

  &__link-text {
    color: $blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__search {
    width: 100%;
  }

  &__slider {
    margin-top: $gutter-l;
    -ms-grid-row-align: end;
    /* stylelint-disable-next-line */
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-column: 2;
    -ms-grid-row-span: 2;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      grid-row-start: 2;
      -ms-grid-row: 2;
    }

    @media only screen and (max-width: $screen-sm-min - 1) {
      margin-top: 0;
    }
  }

  &__statistics {
    justify-content: space-between;
    -ms-grid-row: 2;

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      /* stylelint-disable-next-line */
      display: -ms-grid;
      display: grid;
      width: 100%;
      height: 180px;
      padding-top: $spacer-s;
      grid-template-columns: 50% 50%;
      justify-self: end;
      -ms-grid-columns: 50% 50%;
      -ms-grid-rows: 50% 50%;
    }

    @media only screen and (max-width: $screen-sm-min - 1) {
      display: none;
    }

    .#{$prefix}-home-statistics__item {
      padding-right: 0;

      @media only screen and (min-width: $screen-sm-min) {
        align-self: flex-end;
        padding-right: 0;
        padding-bottom: 0;
      }

      @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        -ms-grid-row-align: end;
      }
    }
  }

  &--without-slider {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: $spacer-l;
    }

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      padding-bottom: $spacer-xl;
    }

    .#{$prefix}-home-hero-unit__statistics {
      width: 600px;

      @media only screen and (max-width: $screen-sm-min - 1) {
        display: grid;
        width: 100%;
      }
    }
  }

  &--without-slider &__search {
    @media only screen and (max-width: $screen-xs-max) {
      margin-top: 0;
    }

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin-top: $spacer-s;
    }
  }
}
