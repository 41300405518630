@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

$action-bar-breakpoint: 1150px;

.sb-wrapper {
  padding: 0;

  @media only screen and (min-width: $screen-md-min) {
    padding: 20px;
    background-color: $grey-2;
  }
}

.#{$prefix}-hero-unit {
  background-color: $grey-1;

  @media only screen and (min-width: $screen-sm-min) {
    padding-bottom: $spacer;
    /* stylelint-disable-next-line */
    display: -ms-grid;
    display: grid;
    grid-template-columns: 55% 40%;
    grid-column-gap: 5%;
    -ms-grid-columns: 55% 45%;
  }

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    padding-bottom: $spacer;
  }

  @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
    padding: 0 0 $spacer;
  }

  &__text-content {
    margin-right: $spacer-m;
    padding-bottom: $spacer-l;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      margin-right: 0;
      padding-bottom: $spacer-m;
    }

    &-first-item {
      margin-bottom: 0;
      color: $gold;

      @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        font-size: $m-b3;
      }
    }

    &-second-item {
      margin-top: $spacer-m;

      @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
        font-size: $m-b1;
      }
    }

    &--type {
      @include font-size--base-2();

      margin-left: $spacer-s;
      color: $white;
    }
  }

  &__carousel {
    justify-self: end;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    -ms-grid-column-align: end;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
  }

  &__action-bar {
    grid-column-start: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 2;

    @media screen and (min-width: $screen-sm-min) {
      -ms-grid-row-align: end;
      align-self: flex-end;
      justify-self: flex-start;
    }

    @media screen and (max-width: $screen-sm-min - 1) {
      margin-top: $spacer-m;
    }

    @media screen and (min-width: $screen-sm-min) and (max-width: $action-bar-breakpoint - 1) {
      grid-column-end: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      margin-top: $spacer-m;
    }
  }

  &--simple {
    padding: 0;

    .#{$prefix}-hero-unit__text-content-first-item {
      @include heading-large;

      margin-top: $spacer-s;
      margin-bottom: $spacer-s;
      padding-top: $spacer-l;
      color: white;

      em {
        color: $gold;
        font-style: normal;
      }
    }
  }
}

.#{$prefix}-tag-hero-unit {
  display: block;
  margin-top: $spacer-l;

  .#{$prefix}-hero-unit__text-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .#{$prefix}-hero-unit__text-content-second-item {
    margin-top: 0;
    margin-bottom: $spacer-xxs;
    font-size: $font-size-base;
  }

  .#{$prefix}-hero-unit__search {
    max-width: 600px;
    margin: auto;
    margin-bottom: $spacer-l;
  }
}
