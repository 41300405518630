@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-progress-bar {
  &__inner {
    display: flex;
    align-items: center;
  }

  &__error-message {
    color: $red--dark;
    font-family: $font-family-book;
    font-size: $font-size-base;

    .#{$prefix}-link {
      font-family: $font-family-book;
    }
  }

  &__meter {
    width: 100%;
    height: 8px;
    margin: $spacer-xs .5rem $spacer-xs 0;
    border-radius: 5px;
    background-color: $grey-5;

    &--filled {
      height: 8px;
      border-radius: 5px;
      background-color: $gold;
    }
  }
}
