@import "../../../../colors";
@import "../../../identity/typography";

$ds-comment-alerted-background: $gold--lighter;
$ds-comment-alerted-color: $gold--dark3;

.#{$prefix}-blog-post-comment {
  display: flex;
  flex-direction: column;
  background-color: $white;
  font-size: $font-size-base;

  &__header {
    display: flex;
  }

  &__meta-info {
    display: flex;
    flex-direction: column;
  }

  &__content {
    @include body;

    padding: $spacer-xs 0 $spacer-m;
  }

  &--alerted,
  &--moderation {
    padding: 0;
    border-top: 1px solid $ds-comment-alerted-color;

    > .#{$prefix}-blog-post-comment {
      &__wrapper {
        padding: $spacer-m;
        background-color: $ds-comment-alerted-background;
      }
    }
  }

  &__alert-toggle {
    @include font-size--base-1;

    display: flex;
    align-items: center;
    margin-bottom: $spacer-s;
    padding: 0;
    border: none;
    outline: 0;
    background: none;
    color: $gold--dark3;
    text-align: left;
    cursor: pointer;

    :last-child {
      margin-left: $spacer-xs;
      outline: 0;
    }
  }

  &__alert-info,
  &__moderation-info {
    margin-bottom: $spacer-s;
  }

  &__moderation-info {
    display: block;
    color: $gold--dark3;
  }

  &__alert {
    @include body;

    padding: $spacer-s 0;
    color: $gold--dark3;

    &:not(:last-child) {
      border-bottom: 1px solid $ds-comment-alerted-color;
    }

    >:not(:last-child) {
      margin-bottom: $spacer-xs;
    }
  }

  &__alert-author {
    a {
      color: $ds-comment-alerted-color;
    }
  }

  &__message {
    width: 100%;

    &--delete {
      margin-bottom: 0;
    }
  }

  &__avatar {
    margin-right: $spacer-s;
  }

  &__author {
    display: block;
    margin-bottom: $spacer-xxs;
    color: $black;

    &:hover {
      color: $blue;
    }
  }

  &__date {
    color: $grey-3;
    font-size: $ds-font-size-xxs;
  }

  &--deleted {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
  }

  &__comments {
    margin-top: $spacer-m;
    padding-left: $spacer-xl;

    $_nested-selector: ".#{$prefix}-blog-post-comment__comments";

    .#{$prefix}-blog-post-comment {
      &:not(:first-of-type) {
        margin-top: $spacer-m;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      margin-top: $spacer-s;
      padding-left: $spacer-s;
      border-left: 1px solid $grey-6;

      #{$_nested-selector} {
        border-left: 1px solid $grey-6;

        #{$_nested-selector} {
          border-left: 1px solid $grey-5;

          #{$_nested-selector} {
            border-left: 1px solid $grey-4;
          }
        }
      }
    }
  }
}
