@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "../../../colors";
@import "~sap-global-ds/src/core/core";

%resetlist {
  margin: 0;
  padding: 0;
  list-style: none;
}

%borderbox {
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

.#{$prefix}-footer {
  @extend %borderbox;

  flex: 0 0 auto;
  width: 100%;
  padding: 20px 0;

  &__inner-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: $screen-lg-min;
    margin: 0 auto;

    @media screen and (max-width: $screen-lg-min - 1) {
      flex-direction: column-reverse;
      justify-content: space-between;
      max-width: $screen-lg-min;
      margin: 0 auto;
    }
  }

  &__navigation {
    @media screen and (max-width: $screen-sm-min - 1) {
      width: 100%;
      margin-top: $spacer-xs;
    }

    &-list {
      @extend %resetlist;

      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      padding: 0 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      list-style-type: none;

      li {
        margin-right: $spacer;
        float: left;
        text-align: center;
        list-style-type: none;

        @media screen and (max-width: $screen-sm-min - 1) {
          width: 50%;
          margin: 0;
          padding: 12px;
          box-shadow:
            1px 0 0 0 #c6c6c6,
            0 1px 0 0 #c6c6c6,
            1px 1px 0 0 #c6c6c6,
            1px 0 0 0 #c6c6c6 inset,
            0 1px 0 0 #c6c6c6 inset;
        }

        a {
          color: $black;
          font-family: $font-family-regular;
          font-size: 12px;
          font-weight: $font-regular;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }

          &:active,
          &:visited {
            color: $black;
          }

          .#{$prefix}-footer--dark & {
            color: $grey-5;

            &:active,
            &:visited {
              color: $grey-5;
            }
          }
        }
      }
    }
  }

  .#{$prefix}-socialmedia {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    @media (max-width: $screen-lg-min - 1) {
      flex-flow: column wrap;
      justify-content: flex-start;
    }

    &__headline {
      margin: 0;
      color: $black;
      font-family: $font-family-medium;
      font-size: 15px;
      font-weight: $font-regular;

      @media (min-width: $screen-lg-min - 1) {
        margin-right: 15px;
      }
    }

    &__links {
      @extend %resetlist;

      display: block;
      position: relative;
      top: 2px;
      margin-top: 5px;
      margin-bottom: 8px;
      font-weight: normal;
      list-style-type: none;

      @media (min-width: $screen-md-min) {
        margin-bottom: 15px;
      }

      @media (min-width: $screen-md-min) {
        margin-top: 0;
        margin-bottom: 0;
      }

      li {
        margin-right: $spacer;
        float: left;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &--dark {
    background-color: $grey-1;

    .#{$prefix}-socialmedia__headline {
      color: $grey-5;
    }
  }

  &--light {
    background-color: $grey-6;
  }
}
