@import "~sap-global-ds/src/core/core";

@mixin ds-content-action-bar-colors($action-color, $hover-color) {
  .#{$prefix}-content-actionbar__link:hover {
    color: $blue;
  }

  @each $container, $inner in (
    ".#{$prefix}-content-actionbar__link": ".#{$prefix}-button__inner-container",
    ".#{$prefix}-select-component__opener" : ".#{$prefix}-select-component__opener-inner"
  ) {
    #{$container},
    #{$inner} {
      color: $action-color;
    }

    @each $state in ("hover", "focus") {
      #{$container}:#{$state} > #{$inner} {
        color: $hover-color;
      }
    }
  }
}
