@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors";
@import "../theme-wrapper/styles.scss";
@import "../../identity/typography";

$icon-dots: "data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22ico-3dots%22%20viewBox%3D%220%200%2015%203%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cstyle%3E.st0%7Bfill%3A%23727272%7D%3C/style%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%221.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%227.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%2213.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3C/svg%3E";
$icon-dots--white: "data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22ico-3dots%22%20viewBox%3D%220%200%2015%203%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cstyle%3E.st0%7Bfill%3A%23fff%7D%3C/style%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%221.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%227.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%2213.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3C/svg%3E";

.#{$prefix}-subheader {
  display: flex;
  position: relative;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0;
  padding: 24px 0 0;
  border-bottom: 1px solid $grey-2;
  background-color: transparent;
  color: $grey-2;
  font-size: $font-size-base;

  @media screen and (min-width: $screen-sm-min) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .#{$prefix}-icon-encoded--3dots {
    width: 15px;
    background-image: url($icon-dots);
  }

  .#{$prefix}-breadcrumb-nav,
  &-actions {
    margin: 0;
    padding: 0;
    color: $grey-2;
    font-family: $font-family-book;
    list-style: none;

    li {
      color: $grey-2;
      text-decoration: none;
      cursor: default;

      a {
        color: $grey-2;
        text-decoration: none;

        &:active,
        &:visited {
          color: $grey-2;
        }

        &:hover,
        &:focus {
          color: $blue--dark;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    > li {
      display: flex;
      align-items: center;
      float: left;
      line-height: 40px;
    }
  }

  .#{$prefix}-breadcrumb-nav {
    display: none;

    @media screen and (min-width: $screen-sm-min) {
      display: inline-block;
    }

    > li {
      > a {
        color: $grey-2;
      }
    }

    &__arrow {
      margin: 0 $spacer-xs;
    }
  }

  &-actions {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;

    @media screen and (min-width: $screen-sm-min) {
      width: auto;
    }

    > li {
      + li {
        margin-left: $spacer-m;
      }

      > a {
        color: $blue;
        white-space: nowrap;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    &__dropdown {
      display: block !important;
      position: relative;
      margin-left: auto !important;

      @media screen and (min-width: $screen-sm-min) {
        margin-left: $spacer-xs !important;
      }

      &-toggle {
        display: inline-block;
        position: relative;
        padding: 0 $spacer-s;
        border: none;
        background-color: transparent;
        line-height: 40px;
        text-decoration: none;

        .#{$prefix}-icon-encoded--3dots {
          top: -3px;
        }

        &:hover,
        &:active,
        &:focus {
          outline: none;
          background-color: $grey-5;
          cursor: pointer;
        }
      }

      &-menu {
        display: none;
        position: absolute;
        right: 0;
        width: auto;
        min-width: 200px;
        margin: 0;
        padding: 0;
        background-color: $white;
        font-family: $font-family-book;
        list-style: none;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
        z-index: 110;

        > li {
          line-height: 16px;

          a {
            display: block;
            padding: $spacer-s $spacer-m;
            color: $grey-1 !important;

            &:hover,
            &:focus,
            &:active {
              background-color: $grey-5;
              text-decoration: none !important;
            }

            &.#{$prefix}-subheader-actions__link--active {
              background-color: $grey-5;
            }
          }
        }
      }

      &--open &-menu {
        display: block;
      }
    }
  }

  &-login-button {
    height: 1.875rem;
    margin-left: auto;
    font-size: $ds-font-size-xxs;

    &::before {
      content: "Login / Sign-up";
    }

    @media screen and (min-width: $screen-sm-min) {
      margin-left: $spacer-s;
    }

    @media screen and (max-width: $screen-sm-min - 1) {
      &::before {
        content: "Login";
      }
    }
  }
}

@include ds-theme(dark) {
  .#{$prefix}-subheader {
    border-bottom-color: $grey-5;
    background-color: $grey-1;

    .#{$prefix}-breadcrumb-nav,
    &-actions {
      li {
        color: $grey-5;

        a {
          color: $grey-5;
          text-decoration: none;

          &:active,
          &:visited {
            color: $grey-5;
          }

          &:hover,
          &:focus {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &__dropdown-toggle {
        &:hover,
        &:focus,
        &:active {
          .#{$prefix}-icon-encoded--3dots {
            background-image: url($icon-dots);
          }
        }
      }
    }

    .#{$prefix}-icon-encoded--3dots {
      background-image: url($icon-dots--white);
    }
  }
}
