@import "../../../../colors";

.#{$prefix}-blog-post-comments {
  &__counter {
    @include heading-small;

    padding: $spacer-l 0 $spacer-s;
    border-bottom: 1px solid $grey-3;

    @media screen and (max-width: $screen-xs-max) {
      padding-bottom: $spacer-xs;
    }
  }

  &__list {
    padding-bottom: $spacer-xl;

    > .#{$prefix}-blog-post-comment {
      margin-top: $spacer-xl;
      padding: $spacer-m;

      @media screen and (max-width: $screen-sm-max) {
        margin-top: $spacer-l;
        padding: $spacer-m;
      }

      @media screen and (max-width: $screen-xs-max) {
        margin: $spacer-m calc(#{$spacer-s} * -1) 0;
        padding: $spacer-m $spacer-s;
      }

      &--alerted,
      &--moderation {
        padding: 0;

        > .#{$prefix}-blog-post-comment {
          &__comments {
            margin-bottom: $spacer-m;
            margin-left: $spacer-l;
            padding: 0 $spacer-m 0;

            @media screen and (max-width: $screen-xs-max) {
              margin-left: $spacer-s;
              padding-right: $spacer-s;
              padding-left: $spacer-s;
            }
          }
        }
      }

      &--deleted {
        @media screen and (max-width: $screen-xs-max) {
          padding: 0 $spacer-s;
        }
      }
    }
  }
}
