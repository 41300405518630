@import "~sap-global-ds/src/core/core";

.#{$prefix}-redirect-accessibility {
  display: block;
  position: relative;
  z-index: 10;

  a {
    position: absolute;
    top: auto;
    left: -10000px;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
