@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-relatedContent {
  margin: 0;
  padding: 0;

  &__list {
    list-style: none;
    /* No greater than $screen-sm-max, no less than $screen-sm-min */
    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: $spacer-xl;
    }
  }

  &__item {
    padding: $spacer-s 0;
    color: $blue;
    font-family: $font-family-sap;
    font-size: $font-size-base;

    &-metadata,
    &-date {
      padding: $spacer-xs 0 0 0;
      color: $grey-1;
      font-size: 12px;
    }

    &-username {
      @include font--primary-regular();

      padding: 0 $spacer-xs 0 0;
      font-size: .75rem !important;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $grey-3;
    }
  }

  &__empty-list {
    display: block;
    margin: $spacer-s 0;
    color: $grey-1;
  }
}
