@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors.scss";

.#{$prefix}-best-bets {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: $spacer-s;
  color: $black;
  font-family: $font-family-book;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  font-stretch: normal;

  &__list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacer-s;
    background-color: $grey-6;
  }

  &__wrapper-link {
    @include font-size--base-1();

    padding-top: $spacer-m;
    padding-right: $spacer-m;
    padding-left: $spacer-m;
    font-family: $font-family-regular;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    text-decoration: none;
    font-stretch: normal;
  }

  &__bet-title {
    color: $link-color;
    font-size: 16px;
  }

  &__headline {
    width: 100%;
    margin-bottom: $spacer-xs;
  }

  & &__label {
    height: 20px;
    padding-top: 1px;
    color: $gold--dark1;
    font-size: 14px;
    text-align: left;
  }

  &__label::after {
    position: relative;
    top: -1px;
    padding: 0 6px;
    color: $gold--dark1;
    font-size: 14px;
    font-weight: $font-light;
    content: "|";
  }

  &__description {
    margin-bottom: $spacer-m;
    font-family: $font-family-book;
    font-size: 14px;
  }
}
