@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
$icon-font-path: "../../assets/fonts/";

@import "~sap-global-ds/src/identity/icons";
@import "~sap-global-ds/src/components/message";
@import "~sap-global-ds/src/components/buttons";

.#{$prefix}-message {
  flex-wrap: nowrap;
  margin-bottom: $spacer-m;

  @media only screen and (max-width: $screen-sm-min) {
    margin-bottom: $spacer-s;

    &:not(.#{$prefix}-message--has-cancel-icon) {
      flex-wrap: wrap;
    }
  }

  &--is-hidden {
    display: none;
  }

  &__action-bar {
    flex-direction: column;

    button {
      white-space: nowrap;
    }

    .#{$prefix}-button + .#{$prefix}-button {
      margin: $spacer-xs 0 0;
    }

    @media only screen and (max-width: $screen-sm-min) {
      flex-direction: row;

      .#{$prefix}-button + .#{$prefix}-button {
        margin-top: 0;
        margin-left: $spacer-xs;
      }
    }
  }

  &__content {
    align-items: start;
  }

  &__close-icon {
    align-self: flex-start;
    margin-right: calc(#{$spacer-s} * -1);
    padding: $spacer-xs;
    border: 0;
    background: none;
    color: $grey-1;
    cursor: pointer;

    &:hover,
    &:focus {
      background: none;
      color: $grey-2;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a.ds-blue-link {
    color: $blue;
  }
}
