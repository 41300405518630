@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../../colors.scss";

.#{$prefix}-content-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: $screen-md-min - 1) {
    margin-bottom: $spacer-l;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer-m;
    color: $black;
    font-size: 21px;

    @media only screen and (max-width: $screen-sm-min - 1) {
      flex-direction: column;
    }
    .#{$prefix}-result-sorting {
      align-self: flex-end;
    }
  }

  &__label-and-link {
    display: flex;

    @media only screen and (max-width: $screen-sm-min - 1) {
      justify-content: space-between;
      margin-bottom: $spacer-m;
    }
  }

  &__label {
    display: flex;
    align-items: flex-end;
    margin-right: $spacer-s;
    color: $black;

    @media only screen and (max-width: $screen-md-min - 1) {
      font-size: 18px;
    }

    @media only screen and (max-width: $screen-sm-min - 1) {
      font-size: 16px;
    }
  }

  &__create-link a {
    font-size: 14px;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__item-wrapper {
    padding: $spacer-s;
    border-bottom: 1px solid $grey-4;
  }

  &__item-wrapper:first-child {
    border-top: 1px solid $grey-4;
  }

  &__item-wrapper:last-child {
    border-bottom: none;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}
