@import "~sap-global-ds/src/core/core";
@import "../../identity/typography";

.#{$prefix}-all-resources {
  &__hero-unit {
    display: flex;
    padding-top: $spacer-l;

    .#{$prefix}-hero-unit__text-content-second-item {
      font-size: $ds-font-size-medium;

      @media screen and (min-width: $screen-md-min) {
        max-width: 90%;
      }
    }
  }

  &__content-wrapper {
    flex: 1 0 auto;
    margin: $spacer-xl 0 $spacer-xxl;
  }
}
