@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "../../identity/typography";

$avatarSize: 2.5rem;
$imageSize: 70px;

.#{$prefix}-content-list-item {
  line-height: $font-size-base;

  &__title:focus &__heading--link {
    outline: 2px solid $blue;
  }

  &__title:focus:not(:focus-visible) &__heading--link {
    outline: 0;
  }

  &__title:focus-visible &__heading--link {
    outline: 2px solid $blue;
  }

  &__heading {
    word-break: break-word;
    margin-bottom: $spacer-xxs;

    &--link {
      color: $blue;
    }
  }

  &__description {
    display: inline-block;
    color: $grey-1;
    font-family: $font-family-book;
    font-size: $font-size-base;
    line-height: 1.5;
    word-break: break-word;
  }

  &__associated-tags-wrapper {
    margin-top: $spacer-s;
  }

  &__associated-tag {
    display: inline !important;
    color: $grey-2;
    font-family: $font-family-book;
    font-size: $ds-font-size-xxs;
    line-height: 1.5;

    &:not(:last-of-type)::after {
      margin: 0 $spacer-xxs;
      content: "|";
    }
  }
}

.#{$prefix}-blog-posts-list-item, .#{$prefix}-questions-list-item, .#{$prefix}-documents-list-item {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $spacer-xs;
  }

  &__info {
    margin-right: $spacer-xxs;
    color: $grey-1;
    font-size: $ds-font-size-xxs;

    &:last-child {
      color: $grey-2;

      &::before {
        margin-right: $spacer-xxs;
        content: "|";
      }
    }
  }

  &__author {
    text-transform: capitalize;

    &-role {
      margin-right: $spacer-xxs;
      font-size: $ds-font-size-xxs;
    }

    &-avatar {
      display: inline;
      margin-right: $spacer-m;
      float: left;
    }
  }

  &__social-stats-wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  &__social-stat {
    display: inline-flex;
    align-items: center;
    margin-left: $spacer-s;
    color: $grey-2;
    font-size: $font-size-base;

    i {
      margin-right: $spacer-xxs;
    }
  }

  .#{$prefix}-content-list-item__content-wrapper {
    padding-left: 0;
  }

  &__question--closed {
    padding: 2px $spacer-xxs;
    background-color: $grey-5;
    color: $grey-1;
    font-size: $font-size-base;
  }

  .#{$prefix}-icon--green {
    color: $green--dark1;
  }
}

.#{$prefix}-blog-posts-list-item, .#{$prefix}-questions-list-item--with-avatar, .#{$prefix}-people-list-item, .#{$prefix}-documents-list-item {
  .#{$prefix}-content-list-item__content-wrapper {
    padding-left: calc(#{$avatarSize} + #{$spacer-m});

    @media only screen and (max-width: $screen-xs-max) {
      padding-left: 0;
    }
  }

  .#{$prefix}-content-list-item__heading {
    padding-left: calc(#{$avatarSize} + #{$spacer-m});

    @media screen and (max-width: $screen-xs-max) {
      min-height: $avatarSize;
    }
  }
}

.#{$prefix}-learning-content-list-item, .#{$prefix}-events-list-item {
  &__header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacer-xs;

    &-content {
      color: $grey-2;
      font-size: $ds-font-size-xxs;

      &:not(:first-child)::before {
        padding: 0 $spacer-xs;
        content: "|";
      }

      &--date {
        color: $grey-1;
      }
    }
  }

  &__content-type {
    color: $grey-1;
  }
}

.#{$prefix}-missions-list-item {
  &__image {
    display: inline;
    width: $imageSize;
    height: $imageSize;
    margin-right: $spacer-m;
    float: left;
  }

  .#{$prefix}-content-list-item__content-wrapper {
    padding-left: calc(#{$imageSize} + #{$spacer-m});

    @media only screen and (max-width: $screen-xs-max) {
      padding-left: 0;
    }
  }

  .#{$prefix}-content-list-item__heading {
    font-size: 21px;

    @media screen and (max-width: $screen-xs-max) {
      min-height: $imageSize;
    }
  }
}

.#{$prefix}-community-page-list-item, .#{$prefix}-people-list-item {
  &__header {
    margin-bottom: $spacer-xs;
  }

  &__info {
    margin-right: $spacer-xxs;
    color: $grey-1;
    font-size: $ds-font-size-xxs;

    &:last-child {
      color: $grey-2;

      &::before {
        margin-right: $spacer-xxs;
        content: "|";
      }
    }
  }

  &__author-avatar {
    display: inline;
    margin-right: $spacer-m;
    float: left;
  }
}
