@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/core/utilities";
@import "~sap-global-ds/src/components/sap-logo";
@import "../../../icons.scss";

.#{$prefix}-icon-encoded--employee {
  width: 24px !important;
  background-size: 24px 12px !important;
}

.#{$prefix}-icon-encoded--topic-expert {
  width: 18px !important;
  background: url($icon-topic-expert-encoded-svg);
  background-size: 18px 12px !important;
}
