@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";
@import "~react-slidedown/lib/slidedown.css";

.#{$prefix}-similar-questions {
  display: block;
  max-height: 17.5rem;
  transition: max-height .25s ease-in-out;
  overflow: hidden;

  &__title {
    @include heading-xsmall;
    @include font--primary-medium($grey-1);

    margin: 0 0 $spacer-m;
  }

  &__question-list {
    max-height: 15rem;
    margin: 0;
    padding: $spacer-xs 0;
    transition: max-height .25s ease-in;
    transition-delay: .25s;
    border: 1px solid $control-border-color-active;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__question {
    display: block;
    list-style: none;
  }

  &__question-link {
    display: block;
    width: 100%;
    padding: $spacer-xxs $spacer-xs;

    &:hover,
    &:focus,
    &--active {
      outline: none;
      background-color: $grey-5;
      text-decoration: none;
    }
  }

  &--hidden {
    max-height: 0;
    margin: 0;
    transition: max-height .25s linear, visibility 0s linear .25s;
    visibility: hidden;
  }
}
