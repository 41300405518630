@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

$font-path: "../../assets/fonts/";

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/identity/fonts";

.#{$prefix}-text-component {
  display: table;
}

.#{$prefix}-font--primary-light {
  @include font--primary-light();
}
.#{$prefix}-font--primary-light-italic {
  @include font--primary-light-italic();
}
.#{$prefix}-font--primary-book {
  @include font--primary-book();
}
.#{$prefix}-font--primary-book-italic {
  @include font--primary-book-italic();
}
.#{$prefix}-font--primary-regular {
  @include font--primary-regular();
}
.#{$prefix}-font--primary-regular-italic {
  @include font--primary-regular-italic();
}
.#{$prefix}-font--primary-medium {
  @include font--primary-medium();
}
.#{$prefix}-font--primary-medium-italic {
  @include font--primary-medium-italic();
}
.#{$prefix}-font--primary-bold {
  @include font--primary-bold();
}
.#{$prefix}-font--primary-bold-italic {
  @include font--primary-bold-italic();
}

.#{$prefix}-font-size-base-0 {
  @include font-size--base-0();
}
.#{$prefix}-font-size-base-1 {
  @include font-size--base-1();
}
.#{$prefix}-font-size-base-2 {
  @include font-size--base-2();
}
.#{$prefix}-font-size-base-3 {
  @include font-size--base-3();
}
.#{$prefix}-font-size-base-4 {
  @include font-size--base-4();
}
.#{$prefix}-font-size-base-5 {
  @include font-size--base-5();
}
.#{$prefix}-font-size-base-6 {
  @include font-size--base-6();
}

.#{$prefix}-font-light #{$prefix}- {
  font-weight: $font-light;
}
.#{$prefix}-font-book {
  font-weight: $font-book;
}
.#{$prefix}-font-regular {
  font-weight: $font-regular;
}
.#{$prefix}-font-medium {
  font-weight: $font-medium;
}
.#{$prefix}-font-bold {
  font-weight: $font-bold;
}

.#{$prefix}-color-blue {
  color: $blue;
}
.#{$prefix}-color-blue--light {
  color: $blue--light;
}
.#{$prefix}-color-blue--light1 {
  color: $blue--light1;
}
.#{$prefix}-color-blue--light2 {
  color: $blue--light2;
}
.#{$prefix}-color-blue--light3 {
  color: $blue--light3;
}
.#{$prefix}-color-blue--dark {
  color: $blue--dark;
}
.#{$prefix}-color-blue--dark1 {
  color: $blue--dark1;
}

.#{$prefix}-color-red {
  color: $red;
}
.#{$prefix}-color-red--light {
  color: $red--light;
}
.#{$prefix}-color-red--light1 {
  color: $red--light1;
}
.#{$prefix}-color-red--light2 {
  color: $red--light2;
}
.#{$prefix}-color-red--light3 {
  color: $red--light3;
}
.#{$prefix}-color-red--dark {
  color: $red--dark;
}
.#{$prefix}-color-red--dark1 {
  color: $red--dark1;
}

.#{$prefix}-color-gold {
  color: $gold;
}
.#{$prefix}-color-gold--light {
  color: $gold--light;
}
.#{$prefix}-color-gold--light1 {
  color: $gold--light1;
}
.#{$prefix}-color-gold--light2 {
  color: $gold--light2;
}
.#{$prefix}-color-gold--light3 {
  color: $gold--light3;
}
.#{$prefix}-color-gold--dark {
  color: $gold--dark;
}
.#{$prefix}-color-gold--dark1 {
  color: $gold--dark1;
}

.#{$prefix}-color-green {
  color: $green;
}
.#{$prefix}-color-green--light {
  color: $green--light;
}
.#{$prefix}-color-green--light1 {
  color: $green--light1;
}
.#{$prefix}-color-green--light2 {
  color: $green--light2;
}
.#{$prefix}-color-green--light3 {
  color: $green--light3;
}
.#{$prefix}-color-green--dark {
  color: $green--dark;
}
.#{$prefix}-color-green--dark1 {
  color: $green--dark1;
}

.#{$prefix}-color-purple {
  color: $purple;
}
.#{$prefix}-color-purple--light {
  color: $purple--light;
}
.#{$prefix}-color-purple--light1 {
  color: $purple--light1;
}
.#{$prefix}-color-purple--light2 {
  color: $purple--light2;
}
.#{$prefix}-color-purple--light3 {
  color: $purple--light3;
}
.#{$prefix}-color-purple--dark {
  color: $purple--dark;
}
.#{$prefix}-color-purple--dark1 {
  color: $purple--dark1;
}

.#{$prefix}-color-black {
  color: $black;
}
.#{$prefix}-color-grey-1 {
  color: $grey-1;
}
.#{$prefix}-color-grey-2 {
  color: $grey-2;
}
.#{$prefix}-color-grey-3 {
  color: $grey-3;
}
.#{$prefix}-color-grey-4 {
  color: $grey-4;
}
.#{$prefix}-color-grey-5 {
  color: $grey-5;
}
.#{$prefix}-color-white {
  color: $white;
}

.#{$prefix}-background-color-blue {
  background-color: $blue;
}
.#{$prefix}-background-color-blue--light {
  background-color: $blue--light;
}
.#{$prefix}-background-color-blue--light1 {
  background-color: $blue--light1;
}
.#{$prefix}-background-color-blue--light2 {
  background-color: $blue--light2;
}
.#{$prefix}-background-color-blue--light3 {
  background-color: $blue--light3;
}
.#{$prefix}-background-color-blue--dark {
  background-color: $blue--dark;
}
.#{$prefix}-background-color-blue--dark1 {
  background-color: $blue--dark1;
}

.#{$prefix}-background-color-red {
  background-color: $red;
}
.#{$prefix}-background-color-red--light {
  background-color: $red--light;
}
.#{$prefix}-background-color-red--light1 {
  background-color: $red--light1;
}
.#{$prefix}-background-color-red--light2 {
  background-color: $red--light2;
}
.#{$prefix}-background-color-red--light3 {
  background-color: $red--light3;
}
.#{$prefix}-background-color-red--dark {
  background-color: $red--dark;
}
.#{$prefix}-background-color-red--dark1 {
  background-color: $red--dark1;
}

.#{$prefix}-background-color-gold {
  background-color: $gold;
}
.#{$prefix}-background-color-gold--light {
  background-color: $gold--light;
}
.#{$prefix}-background-color-gold--light1 {
  background-color: $gold--light1;
}
.#{$prefix}-background-color-gold--light2 {
  background-color: $gold--light2;
}
.#{$prefix}-background-color-gold--light3 {
  background-color: $gold--light3;
}
.#{$prefix}-background-color-gold--dark {
  background-color: $gold--dark;
}
.#{$prefix}-background-color-gold--dark1 {
  background-color: $gold--dark1;
}

.#{$prefix}-background-color-green {
  background-color: $green;
}
.#{$prefix}-background-color-green--light {
  background-color: $green--light;
}
.#{$prefix}-background-color-green--light1 {
  background-color: $green--light1;
}
.#{$prefix}-background-color-green--light2 {
  background-color: $green--light2;
}
.#{$prefix}-background-color-green--light3 {
  background-color: $green--light3;
}
.#{$prefix}-background-color-green--dark {
  background-color: $green--dark;
}
.#{$prefix}-background-color-green--dark1 {
  background-color: $green--dark1;
}

.#{$prefix}-background-color-purple {
  background-color: $purple;
}
.#{$prefix}-background-color-purple--light {
  background-color: $purple--light;
}
.#{$prefix}-background-color-purple--light1 {
  background-color: $purple--light1;
}
.#{$prefix}-background-color-purple--light2 {
  background-color: $purple--light2;
}
.#{$prefix}-background-color-purple--light3 {
  background-color: $purple--light3;
}
.#{$prefix}-background-color-purple--dark {
  background-color: $purple--dark;
}
.#{$prefix}-background-color-purple--dark1 {
  background-color: $purple--dark1;
}

.#{$prefix}-background-color-black {
  background-color: $black;
}
.#{$prefix}-background-color-grey-1 {
  background-color: $grey-1;
}
.#{$prefix}-background-color-grey-2 {
  background-color: $grey-2;
}
.#{$prefix}-background-color-grey-3 {
  background-color: $grey-3;
}
.#{$prefix}-background-color-grey-4 {
  background-color: $grey-4;
}
.#{$prefix}-background-color-grey-5 {
  background-color: $grey-5;
}
.#{$prefix}-background-color-white {
  background-color: $white;
}

.#{$prefix}-background-color-transparent {
  background-color: transparent;
}
