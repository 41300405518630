@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/components/action-bar/mixins";
@import "src/docs/utils/mixins";
@import "src/docs/identity/typography";

.#{$prefix}-comment {
  $ds-comment-reply-icon: "data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' height='512' viewBox='0 0 24 24' width='512' fill='%23969696' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10 7.002v-4.252c0-.301-.181-.573-.458-.691-.276-.117-.599-.058-.814.153l-8.5 8.25c-.146.141-.228.335-.228.538s.082.397.228.538l8.5 8.25c.217.21.539.269.814.153.277-.118.458-.39.458-.691v-4.25h1.418c4.636 0 8.91 2.52 11.153 6.572l.021.038c.134.244.388.39.658.39.062 0 .124-.007.186-.023.332-.085.564-.384.564-.727 0-7.774-6.257-14.114-14-14.248z'/%3E%3C/svg%3E";

  @include fine-print;
  @include ds-content-attachments($ds-font-size-xxs, $spacer-xs);

  display: flex;
  flex-direction: column;
  padding-top: $spacer-xs;
  border-top: solid 1px $grey-3;

  &:first-child {
    border-top: none;
  }

  &__header {
    margin-bottom: $spacer-xs;
  }

  &__date {
    @include fine-print;

    float: right;
    color: $grey-2;
  }

  &__author {
    .#{$prefix}-user__name {
      @include fine-print;

      color: $black;
    }

    display: inline-flex;
  }

  &__content {
    @include ds-editor-content; // SOC-12770 follow up: remove mixin

    margin-bottom: $spacer-xs;
  }

  &__action-bar {
    .#{$prefix}-button__inner-container,
    .#{$prefix}-content-actionbar__link,
    .#{$prefix}-select-component__opener-inner {
      @include fine-print;
    }
    .#{$prefix}-select-component__content {
      @include fine-print;
    }
    .#{$prefix}-icon--cog {
      height: $ds-font-size-xxs;
      line-height: $ds-font-size-xxs;

      &::before {
        font-size: $ds-font-size-xxs;
      }
    }

    @include ds-content-action-bar-colors($grey-2, $blue);
  }

  &__reply {
    display: inline-flex;
  }

  &__reply-icon {
    $ds-icon-size: 14px;

    display: inline-block;
    width: $ds-icon-size;
    height: $ds-icon-size;
    margin: 0 $spacer-xs;
    transform: scale(-1, 1);
    background-image: url($ds-comment-reply-icon);
    background-repeat: no-repeat;
    background-size: $ds-icon-size $ds-icon-size;
  }

  &__reply-name {
    white-space: normal;
  }

  &--alerted {
    $ds-comment-alerted-background: $gold--lighter;
    $ds-comment-alerted-color: $gold--dark3;

    padding: $spacer-s;
    border-top: 2px solid $ds-comment-alerted-color;
    background-color: $ds-comment-alerted-background;

    .#{$prefix}-comment__status {
      @include heading-xsmall;

      margin-bottom: $spacer-s;
      color: $ds-comment-alerted-color;
    }

    &.#{$prefix}-comment:first-child {
      margin-top: $spacer-s;
      border-top: 2px solid $ds-comment-alerted-color;
    }
  }

  &--moderation {
    padding: $spacer-s;
    border-top: 2px solid $grey-2;
    background-color: $grey-6;
    &.#{$prefix}-comment:first-child {
      margin-top: $spacer-s;
      border-top: 2px solid $grey-2;
    }
  }
}
