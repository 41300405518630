@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-search-page {
  &__wrapper {
    flex: 1 0 auto;
    margin: $spacer-xl $spacer-xl $spacer-xxl;

    @media only screen and (max-width: $screen-md-max) {
      margin: $spacer-l $spacer-l $spacer-xxl;
    }

    @media only screen and (max-width: $screen-xs-max) {
      margin: $spacer-m $spacer-xxs $spacer-xl;
    }
  }

  &__hero-unit {
    margin: $spacer-l 0 $spacer-xl;

    &-container,
    &-title {
      margin: auto;
    }

    &-title {
      margin-bottom: $spacer-l;
    }

    &-content {
      display: flex;
      align-items: center;
    }
  }

  &__search-bar {
    flex-grow: 1;
  }

  .#{$prefix}-tooltip__wrapper {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &__filter-results {
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer-m;
    padding: 0 $spacer-xs;
    border-top: 1px solid $grey-4;
    font-size: $font-size-base;
    font-weight: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;

    &__results {
      display: inline-block;
      padding-top: .25rem;
      color: $grey-3;
      font-family: $font-family-regular;
    }
  }

  &__selected-filters {
    padding: 0 0 $spacer-m;

    @media screen and (max-width: $screen-xs-max) {
      border-top: 1px solid $grey-4;
    }
  }

  .#{$prefix}-selectedFilters-separator {
    display: none;
  }

  &__items {
    margin-bottom: $spacer-l;
    padding: 0;
    list-style: none;
  }

  &__item {
    padding: $spacer-m $spacer-xs;
    border-top: 1px solid $grey-4;

    &:first-of-type {
      margin-top: - $spacer-s;
      border-top: none;
    }
  }

  &-loading-indicator__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: $spacer-xl;
  }
}
