@import "~sap-global-ds/src/core/core";
@import "../../../colors";

$ds-search-height: 53px !default;

.#{$prefix}-search {
  $_search-selector: & !global;

  height: $ds-search-height;
  margin-top: 0;
  border-radius: 0;
  color: $grey-3;
  font-family: $font-family-regular;

  &__input-small {
    .#{$prefix}-search,
    .#{$prefix}-search .#{$prefix}-input__box {
      height: auto;
      line-height: normal;
    }

    input {
      height: 32px !important;
      padding-right: 65px !important;
      border-color: $grey-4 !important;
      font-size: $font-size-base;
      line-height: normal !important;
    }

    .#{$prefix}-input__placeholder {
      color: $grey-4;
    }

    .#{$prefix}-search__button-clear {
      top: 50%;
      right: $spacer-xs;

      .#{$prefix}-icon-encoded--close {
        width: $font-size-base;
        height: $font-size-base;
      }
    }
  }

  .#{$prefix}-input__box {
    $_search-input__box-selector: & !global;

    display: block;
    position: relative;
    width: 100%;
    height: $ds-search-height;
    background-color: $white;
  }

  .#{$prefix}-input__control {
    box-sizing: border-box;
    width: 100%;
    height: $ds-search-height;
    padding: 0 $spacer;
    padding-right: 65px;
    border: 1px solid $grey-3;
    border-radius: 0;
    outline: none;
    color: $grey-1;
    font-family: $font-family-regular;
    font-size: $font-size-base;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    &:focus,
    &:hover {
      border: 1px solid $blue--light3;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $grey-3;
      font-style: normal;
    }

    &::-webkit-search-cancel-button {
      display: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &::-ms-clear {
      display: none;
    }

    // fix for iOS zoom on input focus issue
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
      line-height: normal;
    }
  }

  .#{$prefix}-search__button {
    position: absolute;
    top: 49%;
    margin: 0;
    padding: 0;
    transform: translateY(-50%);
    border: none;
    outline: none;
    background-color: transparent;
    line-height: 0;
    cursor: pointer;

    &-submit {
      right: $spacer-s;
      z-index: 2;

      &:hover,
      &:active,
      &:focus {
        .#{$prefix}-icon-encoded--search {
          background-image: url($icon-search-dark-encoded-svg);
        }
      }

      .#{$prefix}-icon-encoded--search {
        display: block;
        width: 18px;
        height: 18px;
        margin: auto;
        background-image: url($icon-search-encoded-svg);
        background-size: 18px 18px;
      }
    }

    &-clear {
      right: 40px;
      z-index: 5;

      &:hover,
      &:active,
      &:focus {
        .#{$prefix}-icon--close-gray {
          color: $grey-0;
        }
      }

      .#{$prefix}-icon--close-gray {
        color: $grey-3;
      }
    }
  }
}

.#{$prefix}-input__placeholder {
  $_search-placeholder-selector: & !global;

  display: block;
  position: absolute;
  top: 50%;
  left: $spacer-xs;
  max-width: calc(100% - 9px);
  padding: 0 $spacer-xs;
  transform: translateY(-50%);
  color: $grey-3;
  font-family: $font-family-regular;
  font-size: $font-size-base;
  font-weight: $font-book;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
}

.#{$prefix}-input--filled {
  .#{$prefix}-input__placeholder {
    display: none;
    color: red;
  }
}

.#{$prefix}-search__button {
  position: absolute;
  top: 50%;
  width: 24px;
  height: 18px;
  height: 100%;
  margin: 0;
  padding: 1px 7px 2px;
  transform: translateY(-50%);
  border: 0;
  outline: none;
  background-color: transparent;
  line-height: 2.9rem;
  cursor: pointer;

  :hover {
    border: none;
  }
}

.#{$prefix}-collapse-input-search {
  height: 1px;
  margin-bottom: $spacer-xxs;
  border-top: 1px solid $grey-4;
  overflow: hidden;
}

.#{$prefix}-mobile-input-search-button {
  padding-left: 0;
  border: 1px solid transparent;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.#{$prefix}-icon-collapse {
  margin-left: 12px;
}
