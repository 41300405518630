@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors.scss";
.#{$prefix}-topic-list {
  &__content-wrapper {
    flex: 1 0 auto;
    margin-top: $spacer-xl;

    @media only screen and (max-width: $screen-md-max) {
      margin-top: $spacer-l;
    }

    @media only screen and (max-width: $screen-xs-max) {
      margin-top: $spacer-m;
    }
  }

  .#{$prefix}-hero-unit {
    padding-bottom: $spacer-xl;

    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: $spacer-l;
    }

    &__text-content {
      padding: $spacer-l 0 0 0;

      &-first-item {
        @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
          font-size: 1.5rem;
        }
      }

      &-second-item {
        margin: 0;
      }
    }
  }

  .#{$prefix}-search-result-wrapper {
    margin-top: 0;
  }

  &__search {
    .#{$prefix}-input__box {
      height: 3rem;

      .#{$prefix}-input__control {
        height: 3rem;
      }
    }
    .#{$prefix}-icon-encoded--search {
      width: 1.625rem !important;
      height: 1.5rem !important;
      background-size: 1.625rem 1.5rem !important;
    }
  }

  &__filter-results {
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer-l;
    color: $grey-2;
    font-family: $font-family-regular;
    font-size: $font-size-base;
    font-weight: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;

    &__results {
      display: inline-block;
      padding-top: .25rem;
      color: $grey-2;
      font-family: $font-family-regular;
    }
  }

  &__sort-options {
    float: right;
    .#{$prefix}-sort-options {
      &__label {
        display: inline-block;
        color: $grey-2;
        font-family: $font-family-regular;
        font-size: $font-size-base;
        font-weight: normal;
        line-height: 1.4;
        cursor: default;
      }

      &__list {
        display: inline-block;
        margin: .25rem 0 0 .125rem;
        padding: 0;
        color: $grey-2;
        list-style: none;

        &--item {
          display: inline-block;
        }
      }

      &__option {
        padding: .125rem .25rem .125rem .25rem;
        color: $grey-2;
        font-family: $font-family-regular;
        font-size: $font-size-base;
        font-weight: normal;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: $grey-1;
        }

        &:focus {
          outline: none;
        }
      }
    }
    .#{$prefix}-is-active {
      background: $grey-5;
      color: $grey-1;
    }
  }

  &__topicList {
    padding: 0;
    list-style: none;

    &__item {
      &:not(:first-of-type) {
        border-top: 1px solid $grey-3;
      }

      &:first-of-type {
        margin-top: - $spacer-s;
      }
    }
  }
  .#{$prefix}-topicListItem {
    margin: 0 $spacer-xs;
    padding: $spacer-s 0;

    &__topicInfo {
      margin-bottom: $spacer-xs;
      font-size: $font-size-base;

      span {
        color: $grey-2;
        font-family: $font-family-book;
        font-weight: normal;
      }
    }

    &__createdAt {
      float: right;
    }

    &__title {
      margin-bottom: $spacer-xxs;
      font-family: $font-family-regular;
      font-size: 1.125rem;
      font-weight: normal;

      &:hover {
        color: $blue;
      }
    }

    &__languages {
      margin-bottom: $spacer-xs;
    }

    &__language {
      &-list {
        display: inline-block;
        padding: 0;
        color: $grey-1;
        list-style: none;
      }

      &-listitem {
        float: left;

        &:not(:last-of-type)::after {
          padding: 0 $spacer-xs;
          color: $grey-3;
          font-size: $font-size-base;
          line-height: .69rem;
          content: "|";
        }
      }

      &-item {
        color: $grey-1;
        font-family: $font-family-book;
        font-size: $font-size-base;
        font-weight: normal;
        text-decoration: none;

        &:hover {
          color: $blue;
          text-decoration: underline;
        }
      }
    }

    &__description {
      color: $grey-1;
      font-family: $font-family-book;
      font-size: $font-size-base;
      font-weight: normal;
      line-height: 1.3125rem;
    }
  }
  .#{$prefix}-sidebar {
    .#{$prefix}-topic-list__category-filter {
      .#{$prefix}-radio__label {
        color: $grey-0 !important;
      }
    }
    .#{$prefix}-filter {
      margin-bottom: $spacer-l;
      &__opener, .#{$prefix}-radio__label, .#{$prefix}-checkbox__label {
        color: $black;
      }
    }
  }
  .#{$prefix}-pagination {
    margin: $spacer-xxl auto;

    &__grid {
      .#{$prefix}-input, button {
        @media only screen and (max-width: $screen-xs-min) {
          width: 2.3rem;
        }
      }

      @media only screen and (max-width: $screen-sm-max) {
        .#{$prefix}-pagination__previous-button {
          margin-right: .35rem;
        }
        .#{$prefix}-pagination__next-button {
          margin-left: .35rem;
        }
        .#{$prefix}-pagination__first-button {
          margin-right: .35rem;
        }
        .#{$prefix}-pagination__last-button {
          margin-left: .35rem;
        }
        .#{$prefix}-input {
          width: 3rem;
          margin: 0 .3rem;
        }
      }
    }
  }
  .#{$prefix}-topic-list-loading-indicator__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: $spacer-xl;
  }
}
