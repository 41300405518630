@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
$icon-font-path: "../../assets/fonts/";

@import "../../../icons.scss";

.#{$prefix}-choicesSelect {
  $_choicesSelect-selector: & !global;

  position: relative;
  outline: none;
  color: $grey-3;
  font-family: $font-family-regular;
  cursor: pointer;

  &__innerContainer {
    $_choicesSelect-innerContainerSelector: & !global;

    position: relative;
    box-sizing: border-box;
    min-height: 2.625rem;
    padding: $control-y-padding $control-x-padding $control-y-padding 0;
    border: 1px solid $control-border-color;
    background-color: $white;
    font-family: $font-family-regular;
    font-size: $font-size-base;

    &::after {
      display: block;
      position: absolute;
      top: calc(50% - 6px);
      right: 8px;
      width: 16px;
      height: 10px;
      background-image: url($icon-arrow-down--gray);
      background-repeat: no-repeat;
      background-size: 16px 10px;
      content: "";
    }
  }

  &__menu {
    $_choicesSelect-menuSelector: & !global;

    max-height: 14rem;
    outline: none;
    color: $grey-3;
    font-family: $font-family-regular;
    font-size: $font-size-base;
    overflow: auto;
    -webkit-overflow-scrolling: touch; //show scroll in IOS
  }

  &__popup {
    $_choicesSelect-popupSelector: & !global;

    display: none;
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    width: 100%;
    max-height: inherit;
    border: 1px solid $control-border-color;
    border: 0 none;
    border-top: none;
    outline: none;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba($black, .08), 0 0 0 1px $grey-5;
    z-index: 1001;

    &--visible {
      display: block;
    }
  }

  &__menuItem {
    color: $grey-1;
    font-family: $font-family-regular;
    font-weight: $font-book;

    &--selectable {
      padding: $control-y-padding * .5 $control-x-padding;
    }

    &--highlighted {
      background-color: $grey-5;
    }
  }

  &__input {
    display: none;
  }

  &__placeholder {
    display: none;

    #{$_choicesSelect-innerContainerSelector} & {
      display: block;
      opacity: 1;
      color: $control-placeholder-color;
    }
  }

  &--open {
    #{$_choicesSelect-innerContainerSelector} {
      border-bottom: none;

      &::after {
        transform: rotate(-180deg);
        transition: transform .1s ease-out;
      }
    }
  }

  &--focused {
    #{$_choicesSelect-innerContainerSelector} {
      border-color: $control-border-color-active;
      color: $control-active-color;

      &::after {
        background-image: url($icon-arrow-down--blue);
      }
    }
    #{$_choicesSelect-popupSelector} {
      border-color: $control-border-color-active;
    }
  }

  &--disabled {
    pointer-events: none;
    #{$_choicesSelect-innerContainerSelector},
    #{$_choicesSelect-menuSelector} {
      background-color: $grey-5;
      color: $grey-1;
    }
  }
}

.#{$prefix}-selectBox {
  display: block;
  position: relative;
  margin-top: $font-size-base/2;

  @at-root {
    select#{&} {
      width: 100%;
      height: 2.5rem;
      padding: $control-y-padding $control-x-padding $control-y-padding $control-x-padding;
      border: 1px solid $control-border-color;
      color: $grey-1;
      font-size: $font-size-base;
    }
  }

  &__label {
    $_label-selector: & !global;

    position: absolute;
    top: 0;
    left: $control-x-padding * .5;
    padding: 0 $control-x-padding * .5;
    transform: translateY(-50%);
    background: $white;
    color: $grey-1;
    font-family: $font-family-book;
    font-size: $font-size-base;
    line-height: normal;

    &::after {
      display: none;
    }
    #{$_choicesSelect-selector}--focused + & {
      color: $control-active-color;
    }
  }

  &__message {
    @include font--primary-book();
    $_message-selector: & !global;

    display: flex;
    align-items: center;
    margin-top: $spacer-xxs;
    font-size: $font-size-base;
    line-height: 1.5;

    .#{$prefix}-icon {
      margin-right: $spacer-xs;

      &::before {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.5rem;
        line-height: 1;
      }
    }
  }

  &--error {
    #{$_message-selector} {
      color: $control-error-color;
    }
    #{$_choicesSelect-innerContainerSelector},
    #{$_choicesSelect-popupSelector} {
      border-color: $control-error-border-color;
    }
    #{$_choicesSelect-innerContainerSelector}:after {
      background-image: url($icon-arrow-down--red);
    }
    #{$_label-selector} {
      color: $control-error-color;
    }
  }

  &--filled:not(&--error) {
    #{$_label-selector} {
      color: $control-active-color;
    }
  }

  &--required &__label::after {
    display: inline;
    margin-left: $spacer-xxs;
    color: $blue;
    content: "*";
  }

  &--required.#{$prefix}-selectBox--error &__label::after {
    color: $red;
  }
}

.#{$prefix}-icon-encoded--arrow-down {
  display: inline-block;
  width: 16px;
  height: 10px;
  background-image: url($icon-arrow-down--gray);
  background-repeat: no-repeat;
  background-size: 16px 10px;
}

.#{$prefix}-creatable-select {
  &__input {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - #{$spacer-l});
    height: 39px;
    padding-left: $control-x-padding;
    border: none;
    outline: none;
    font-family: $font-family-book;
    font-size: $font-size-base;

    &:disabled {
      background-color: $grey-5;
    }

    &::placeholder {
      color: $control-placeholder-color;
    }
  }
}
