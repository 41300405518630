@import "~sap-global-ds/src/core/core";
@import "../../../colors.scss";

.#{$prefix}-mission-and-badges-page {
  &__content-wrapper {
    flex: 1 0 auto;
    margin-top: $spacer-xl;

    @media only screen and (max-width: $screen-md-max) {
      margin-top: $spacer-l;
    }

    @media only screen and (max-width: $screen-xs-max) {
      margin-top: $spacer-m;
    }
  }

  @media only screen and (max-width: $screen-xs-max) {
    padding-bottom: $spacer-l;
  }

  &__text-content {
    padding: $spacer-l 0 0 0;

    &-first-item {
      @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        font-size: 1.5rem;
      }
    }

    &-second-item {
      margin: 0;
    }
  }

  .#{$prefix}-hero-unit {
    padding-bottom: $spacer-xl;

    @media only screen and (max-width: $screen-xs-max) {
      padding-bottom: $spacer-l;
    }

    &__text-content {
      padding-top: $spacer-l;

      &-first-item {
        @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
          font-size: 1.5rem;
        }
      }

      &-second-item {
        margin: 0;
      }
    }
  }

  &__search-wrapper {
    margin-bottom: $spacer-xxs;
    padding-bottom: $spacer-s;
  }

  &__mission-categories {
    margin-top: $spacer-m;
    margin-bottom: $spacer-xxl;
  }

  &__missions {
    margin-top: $spacer-l;
    padding: 0;
    list-style: none;

    &__item {
      &:not(:first-of-type) {
        border-top: 1px solid $grey-3;
      }

      &:first-of-type {
        margin-top: $spacer-s;
      }
    }
  }
}
