@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../../colors.scss";

.#{$prefix}-tab-navigation {
  height: 100%;
}

.#{$prefix}-tab-navigation-menu {
  display: flex;
  width: 100%;
  min-height: 24px;
  padding-left: 0;
  border-bottom: solid 1px $grey-3;
  list-style-type: none;
  padding-inline-start: 0;
}

.#{$prefix}-tab-navigation-item {
  height: 23px;
  margin: 0 .625rem;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: $grey-2;
  font-family: $font-family-book;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  white-space: nowrap;
  cursor: pointer;
  font-stretch: normal;
}

.#{$prefix}-active-tab {
  margin-bottom: -1px;
  border-bottom: 3px solid $gold;
}

.#{$prefix}-tab-navigation-menu.black-and-white {
  height: 41px;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: none;

  .#{$prefix}-tab-navigation-item {
    height: 100%;
    margin: 0;
    padding-right: $spacer-m;
    padding-left: $spacer-m;
    background-color: $grey-0;
    color: $white;
    font-size: 14px;
  }

  .#{$prefix}-active-tab {
    border: none;

    .#{$prefix}-tab-navigation-item {
      background-color: $white;
      color: $grey-0;
    }
  }
}
