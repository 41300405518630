@import "~sap-global-ds/src/core/core";

.#{$prefix}-blog-post-detail {
  @import "forms/form";
  @import "forms/comment-form";
  @import "blog-post";
  @import "comments/comments";
  @import "comments/comment";
  @import "src/docs/layouts/answers/question-detail/user";
}
