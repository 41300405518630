@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/identity/typography";
@import "src/docs/components/action-bar/mixins";
@import "src/docs/utils/mixins";

.#{$prefix}-question {
  $ds-gravatar-width: 2.5rem;
  $ds-left-container-width: $ds-gravatar-width + $spacer-m;

  @include ds-content-attachments($font-size-base, $spacer-s);

  &__title {
    $_question__title-selector: & !global;

    @include heading-medium;

    margin: $spacer-xs 0;
    color: $black;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.4;
  }

  &__posted {
    $_question__posted-selector: & !global;

    @include body;

    display: block;
    color: $grey-2;
    font-size: $font-size-base;
  }

  &__meta-list {
    $_question__meta-list-selector: & !global;
  }

  &__meta {
    @include body;

    display: inline-block;
    color: $grey-2;

    &::after {
      display: inline-block;
      margin-right: $spacer-s;
      margin-left: $spacer-s;
      content: "|";
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__meta-link {
    @include body;

    display: inline-block;
    color: $grey-2;
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }

  &__hero {
    $_question__hero-selector: & !global;

    position: relative;
  }

  &__left-container {
    $_question__left-container-selector: & !global;

    display: flex;
    position: absolute;
    left: $grid-gutter-width-phone/2 + $spacer-xxs;
    flex-direction: column;
    align-items: center;
    width: $ds-gravatar-width;

    //show tooltip at the middle of vote component, not on top as default
    .#{$prefix}-tooltip--right {
      top: 20px;
    }
  }

  &__gravatar {
    margin-bottom: $spacer-l;
  }

  &__vote {
    $_question__vote-selector: & !global;

    margin-bottom: $spacer-l;
  }

  &__heading {
    $_question__heading-selector: & !global;

    width: calc(100% - #{$ds-left-container-width});
    min-height: 160px;
    margin-left: $ds-left-container-width;
  }

  &__follow-container {
    $_question__follow-container-selector: & !global;

    width: 100%;
    margin-top: $spacer-m;
  }

  &__follow-btn {
    $_question__follow-btn-selector: & !global;

    display: inline-flex;
    justify-content: center;
    width: $follow-button-width;
    margin-right: $spacer-m;

    &:hover {
      .#{$prefix}-icon-encoded {
        filter: grayscale(1) brightness(1000);
      }
    }

    &--following:focus {
      background-color: $white !important;
      color: $green--dark !important;
    }
  }

  &__right-container {
    $_question__right-container-selector: & !global;

    display: flex;
    flex-basis: 100%;
    flex-direction: column;
  }

  &__content {
    @include body;
    @include ds-editor-content; // SOC-12770 follow up: remove mixin

    margin-bottom: $spacer-m;
    color: $black;
  }

  &__action-bar {
    @include ds-content-action-bar-colors($grey-2, $blue);

    margin-bottom: $spacer-m;
  }

  &__comments-container {
    $_question__comments-container-selector: & !global;

    padding-top: $spacer-s;
    border-top: solid 1px $grey-4;
  }

  &__comments-form {
    $_question__comments-form-selector: & !global;
  }
}

@at-root {
  @include ds-breakpoint(small-mobile) {
    #{$_question__left-container-selector} {
      align-items: center;
    }
  }

  @include ds-breakpoint(mobile) {
    $ds-follow-btn-width: 7.6rem;
    $ds-left-container-width: $ds-follow-btn-width+$spacer-m;
    $ds-comments-width: 82%;

    #{$_question__hero-selector} {
      margin-bottom: 4rem;
      padding-top: $spacer-s;
    }

    #{$_question__follow-container-selector} {
      display: flex;
      position: absolute;
      top: 170px;
      left: $grid-gutter-width-tablet/2;
      flex-direction: column;
      align-items: center;
      width: $ds-follow-btn-width;
    }

    #{$_question__follow-btn-selector} {
      width: $ds-follow-btn-width;
      margin-right: 0;
      margin-bottom: $spacer-m;
    }

    #{$_question__right-container-selector},
    #{$_question__heading-selector} {
      width: calc(100% - #{$ds-left-container-width});
      margin: 0 0 0 $ds-left-container-width;;
    }

    #{$_question__left-container-selector} {
      left: $grid-gutter-width-tablet/2;
      width: $ds-follow-btn-width;
      padding: 0;
    }

    #{$_question__comments-form-selector} {
      width: $ds-comments-width;
      margin: auto;
    }

    #{$_question__heading-selector} {
      min-height: auto;
      padding: 0;
    }

    #{$_question__vote-selector} {
      margin-left: 0;
    }

    #{$_question__posted-selector} {
      margin-bottom: $spacer-m;
    }
  }

  @include ds-breakpoint(tablets) {
    $ds-follow-btn-width: 7.6rem;
    $ds-left-container-width: $ds-follow-btn-width+$spacer-l;

    #{$_question__left-container-selector} {
      left: $grid-gutter-width/2;
      width: $ds-follow-btn-width;
    }

    #{$_question__follow-container-selector} {
      left: $grid-gutter-width/2;
      align-items: flex-start;
      width: $ds-follow-btn-width;;
    }

    #{$_question__comments-form-selector},
    #{$_question__right-container-selector},
    #{$_question__heading-selector} {
      width: calc(100% - #{$ds-left-container-width});
      margin: 0 0 0 $ds-left-container-width;;
    }

    #{$_question__comments-form-selector} {
      padding-left: $spacer-l;
    }
  }
}
