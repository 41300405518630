@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/components/content-slider";
@import "../../../colors.scss";
@import "../../identity/typography";

.#{$prefix}-content-slider {
  width: 410px;
  height: 230px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    width: 100%;
  }

  @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
    width: 350px;
  }

  &__subtitle {
    color: $grey-0;
    font-size: $ds-font-size-xxs;

    &-location {
      display: block;
      width: 280px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
        width: 213px;
      }
    }
  }

  &__content {
    display: flex;
    display: flexbox;
    width: 100%;
    height: 100%;
    margin: 0;

    &__button {
      position: relative;
      align-self: center;

      &::before {
        color: $grey-3;
      }
    }

    &__button--next {
      margin: 0;

      &::before {
        margin-right: -$spacer-xs;
        float: right;
        font-size: 3.125rem;
      }
    }

    &__slides {
      width: 25rem;
      min-width: 0;
      margin-top: $spacer-s;

      @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        width: 87vw;
      }

      @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        width: 18rem;
      }

      &__slide {
        height: 95%;
        text-decoration: none;
      }

      .#{$prefix}-slider--no-link {
        pointer-events: none;
      }
    }

    &__button--previous {
      margin: 0;

      &::before {
        margin-left: -$spacer-xs;
        float: left;
        font-size: 3.125rem;
      }
    }
  }

  &--slides-1 {
    display: flex;
    justify-content: center;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
      padding: 0 $gutter-s;
    }
  }

  &__title {
    &-container {
      display: flex;
    }

    &-image {
      margin-right: $gutter-s;

      @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
        display: none;
      }
    }

    &-item {
      display: block;
      max-height: 70px;
      margin: $spacer-xs 0 $spacer-s 0;
      font-family: $font-family-regular;
      line-height: 1.29;
      overflow: hidden;
    }
  }

  &__date-location {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__main-content {
    display: flex;

    &-text {
      display: block;
      max-height: 8.5rem;
      color: $grey-1;
      font-family: $font-family-regular;
      font-size: $font-size-base;
      line-height: 1.43;
      text-align: justify;
      overflow: hidden;

      @media only screen and (max-width: $screen-sm-min - 1) {
        max-height: 7.5rem;
      }

      @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        max-height: 6.5rem;
      }
    }
  }

  &__main-image {
    margin-right: $gutter-s;

    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
      display: none;
    }
  }

  &__indicators {
    margin-bottom: $spacer-s;

    &--invisible {
      display: none;
    }
  }
}

.#{$prefix}-icon--calendar {
  &__event {
    margin-right: $gutter-s;

    &::before {
      color: $gold;
      font-size: 33px;
    }
  }
}
