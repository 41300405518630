@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

.#{$prefix}-social-stats {
  display: flex;

  &__stat {
    display: inline-flex;
    align-items: center;
    color: $grey-2;
    font-size: $font-size-base;

    &:not(:first-of-type) {
      margin-left: $spacer-s;
    }

    i {
      margin-right: $spacer-xxs;
    }
  }
}
