//Use this file if you need font-size 12px
//NOTE: THIS FILE SHOULD BE THE LAST IMPORT IN EACH FILE IT IS USED BY
//otherwise this mixins might be overwritten back by "~sap-global-ds/src/core/core"
@import "~sap-global-ds/src/core/core";

$ds-font-size-medium: 16px;
$ds-font-size-xxs: 12px;

/* stylelint-disable scss/at-mixin-pattern */
@mixin font-size--base-0 {
  font-size: $ds-font-size-xxs;

  @include ds-breakpoint(small-mobile) {
    font-size: $ds-font-size-xxs;
  }
}

@mixin body-medium {
  @include font--primary-book;

  font-size: $ds-font-size-medium;
}

@mixin fine-print {
  @include font--primary-book;
  @include font-size--base-0;

  font-weight: normal;
}

@mixin heading-xxsmall {
  @include font-size--base-0;
  @include font--primary-medium;

  letter-spacing: 1px;
  line-height: 1.3;
  text-transform: uppercase;
}
/* stylelint-enable scss/at-mixin-pattern */
