@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

.#{$prefix}-qualtrics-search-survey {
  width: 100%;

  @media only screen and (max-width: $screen-xs-max) {
    display: none;
  }

  &__banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacer-s $spacer-m;
    background-color: $grey-5;

    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }

  &__question {
    margin-right: $spacer-l;
    margin-bottom: 0;

    @media only screen and (max-width: $screen-xs-max) {
      margin-right: 0;
      margin-bottom: $spacer-m;
      text-align: center;
    }
  }

  &__button-wrapper {
    display: flex;
    margin-right: $spacer-l;

    @media only screen and (max-width: $screen-xs-max) {
      margin-right: 0;
    }
  }

  &__button {
    &:not(:first-of-type) {
      margin-left: $spacer-m;
    }
  }
}
