@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "../../identity/typography";

.#{$prefix}-member-dropdown {
  display: inline-flex;
  position: relative;

  &__card {
    display: none;
    position: absolute;
    padding: $spacer-m;

    &-inner {
      display: flex;
      flex-direction: column;
      width: 320px;
      padding: $spacer-m;
      background: $white;
      box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, .15);
    }

    &-arrow--after,
    &-arrow--before {
      position: absolute;
      width: 20px;
      height: 20px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background: $white;
      box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, .15);
      content: "";
      z-index: -1;
    }

    &-arrow--before {
      box-shadow: none;
      z-index: 1;
    }
  }

  &:hover {
    .#{$prefix}-member-dropdown__card {
      display: block;
    }
  }

  &__main-info {
    display: flex;
    align-items: flex-start;
    padding-bottom: $spacer-m;

    &-container {
      padding-left: $spacer-s;
    }
  }

  &__link-name {
    @include font-size--base-2();

    color: $black;
  }

  &__badges-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer-s;
  }

  &__badge {
    width: 40px;
  }

  &__active-member {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacer-xs;
  }

  &__statistics {
    margin-bottom: $spacer-m;
    border-bottom: 1px solid $grey-4;

    &-item {
      display: flex;
      margin-bottom: $spacer-xs;

      .#{$prefix}-text-component:first-child {
        min-width: 20%;
        margin-right: $spacer-s;
        font-size: $ds-font-size-medium;
      }

      .#{$prefix}-text-component:last-child {
        align-self: center;
      }
    }
  }

  &__contributor {
    margin-bottom: $spacer;

    &-title {
      margin-bottom: $spacer-s;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacer-xs;
    }

    &-name {
      max-width: 215px;
      word-break: break-all;
    }

    &-level {
      margin-left: $spacer;
      white-space: nowrap;
    }
  }

  &__follow-button {
    align-self: center;
    background-color: $green--dark;

    &:hover,
    &:focus {
      background-color: $green--dark1;
    }
  }

  &__no-info {
    margin-bottom: $spacer-m;
  }
}
