@import "~sap-global-ds/src/core/core";
@import "../../../colors.scss";

.#{$prefix}-resource-tabs-wrapper {
  background-color: $grey-0;
}

.#{$prefix}-community-content-wrapper {

  .#{$prefix}-search-result-wrapper {
    margin-top: $spacer-xs;
  }

  .#{$prefix}-sidebar--pull-right {
    display: flex;
    justify-content: space-between;
  }

  @include ds-breakpoint(mobile) {

    .#{$prefix}-search-result-wrapper {
      margin-top: $spacer-xl;
    }

    .#{$prefix}-mobile-input-search-button {
      display: none;
    }

    .#{$prefix}-sidebar--pull-right {
      display: block;
    }
  }
}
