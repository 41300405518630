@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

.#{$prefix}-statistics {
  display: inline-flex;
  align-content: center;
  height: 40px;

  &-item {
    display: flex;
    align-self: center;
    padding: 0 $spacer;

    div {
      text-align: center;
    }

    &:last-child {
      padding-right: 0;
      border: none;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &-border {
    align-self: center;
    margin-bottom: $gutter-xxs;
    color: $white;
    font-size: 22px;
    font-weight: $font-book;
  }
}

@media only screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
  .#{$prefix}-statistics {
    height: 1.75rem;

    &-item {
      padding: 0 $spacer-xxs;

      div {
        font-size: 12px;
      }
    }

    &-border {
      font-size: 13px;
    }
  }
}
