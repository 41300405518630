@import "~sap-global-ds/src/core/core";

.#{$prefix}-question-modal {
  &__tip {
    display: block;
  }

  &__select {
    max-width: 275px;
  }

  &__text {
    .#{$prefix}-textField__input {
      resize: vertical;
    }
  }

  .#{$prefix}-modal__box {
    max-width: 620px;
    height: auto;
    min-height: auto;
  }
  .#{$prefix}-modal__box__content {
    flex-basis: auto;
  }

  &--error {
    .ds-modal__box {
      max-width: 450px;
    }

    .ds-modal__box__header__heading {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .ds-modal-link {
      font-size: inherit;
    }

    .ds-modal__box__content,
    .ds-modal__box__action-bar {
      display: none;
    }

    .ds-modal__close-icon.ds-button {
      top: 0;
      padding-top: 1.25rem;
    }
  }
}
