@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
//lightbox component
.#{$prefix}-lightbox {
  display: none;

  &__box {
    display: flex;
    position: relative;
    flex-basis: 95%;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 80%;
    min-height: 31.25rem;
    padding: $spacer-m 0;
    background-color: $white;
    text-align: center;
  }

  &--active {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(114, 114, 114, .5); // $grey-2 with opacity
    z-index: 100;
  }

  &__slide {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;

    &Number {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0 5px 5px 0;
      color: rgba(0, 0, 0, .8);
      font-size: 16px;
    }

    &--img {
      display: block;
      flex: 0 0 auto;
      align-self: center;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 $spacer-l $spacer-s $spacer-l;

    &Icon {
      position: absolute;
      right: 2rem;
      transition: all .2s ease-in-out;
      cursor: pointer;

      &::before {
        font-size: 1rem;
      }

      &:hover,
      &:focus {
        transform: scale(1.1);
        opacity: .6;
        cursor: pointer;
      }
    }
  }

  &__content {
    flex-basis: 90%;
    margin: auto;

    &--scrollable {
      display: flex;
      width: 90%;
      overflow-y: auto;
    }

    .ds-icon--arrow-left,
    .ds-icon--arrow-right {
      position: absolute;
      top: 45%;
      width: auto;
      padding: 0;
      cursor: pointer;

      &:hover::before,
      &:focus::before {
        color: $blue;
      }

      &::before {
        color: $grey-3;
        font-size: 4rem;
      }
    }

    .ds-icon--arrow-right {
      right: 0;
    }

    .ds-icon--arrow-left {
      left: 0;
    }
  }

  &__img {
    cursor: pointer;
  }

  &__zoom-in,
  &__zoom-out {
    margin: 20px 50px;
    cursor: pointer;

    &:hover::before,
    &:focus::before {
      color: $blue;
    }

    &::before {
      color: $grey-3;
      font-size: 32px;
    }
  }

  &__gallery-button {
    padding: 0;
    border: none;
  }
}
