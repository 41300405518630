@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~sap-global-ds/src/identity/icons";
@import "~sap-global-ds/src/components/checkbox";

$checkbox-size: 19px;

.#{$prefix}-checkbox {
  position: relative;

  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: $checkbox-size;
    height: $checkbox-size;
    margin: 0;
    opacity: 0;
    visibility: visible;

    &:disabled ~ .#{$prefix}-checkbox__label {
      color: $grey-4;
    }
  }

  &__label {
    padding-top: 1px;
    font-size: $font-size-base;
  }

  &__box {
    width: $checkbox-size;
    min-width: $checkbox-size;
    height: $checkbox-size;
    min-height: $checkbox-size;
  }

  &--disabled:hover {
    cursor: default;
  }
}

.#{$prefix}-icon--checked:before {
  font-size: .8rem !important;
  text-align: center;
}
