@import "~sap-global-ds/src/core/core";
@import "../../colors.scss";

@mixin ds-ie-only($selector, $map ) {
  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, #{$selector} {
      @each $property, $value in ($map) {
        #{$property}: $value+\0;
      }
    }
  }
}

@mixin ds-button-link($block, $inner-block) {
  #{$block} {
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  #{$block} > #{$inner-block} {
    outline: none;

    &:focus {
      outline: none;
    }
  }

  #{$block}:focus > #{$inner-block} {
    box-shadow: 0 0 2px 2px $blue;
  }

  @each $status in  ("focus", "hover") {
    @include ds-ie-only("#{$block}:#{$status} > #{$inner-block}", (
      color: $blue,
      box-shadow: none
    ));
  }
}

/* stylelint-disable selector-max-type */
@mixin ds-editor-content() {
  word-break: break-word;
  word-wrap: break-word;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .#{$prefix}-attachment {
    margin-bottom: $spacer-xs;
  }

  p {
    word-break: break-word;
    word-wrap: break-word;
    margin-bottom: $spacer-m;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    margin: $spacer-m 0 $spacer-m 0;
    padding: 0 0 0 $spacer-s;
    border-left: 4px solid $grey-4;
    font-style: italic;

    p {
      margin: 0 0 $spacer-xs 0;
      font-size: $font-size-base;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  pre {
    margin: $spacer-m 0 $spacer-m 0;
    padding: $spacer-m;
    border: 1px solid $grey-4;
    background-color: $grey-6;
    overflow-x: auto;
    word-wrap: normal;
    word-break: normal;
    -webkit-overflow-scrolling: touch;

    p {
      margin: 0 0 $spacer-xs 0;
    }
  }

  ul {
    margin: $spacer-s 0 $spacer-s $spacer-m;
    padding: 0;
    line-height: 1.5;
    list-style-type: disc;

    ul {
      margin: $spacer-xxs 0 $spacer-xxs $spacer-l;
      list-style-type: circle;
      padding: 0;

      ul {
        margin: $spacer-xxs 0 $spacer-xxs $spacer-l;
        list-style-type: disc;
      }
    }

    li {
      padding-bottom: $spacer-xxs;
    }
  }

  ol {
    margin: $spacer-xxs 0 $spacer-xxs $spacer-l;
    padding: 0;
    line-height: 1.5;
    list-style: decimal;

    ol {
      margin: $spacer-xxs 0 $spacer-xxs $spacer-l;
      padding: 0;
      list-style: lower-roman;

      ol {
        margin: $spacer-xxs 0 $spacer-xxs $spacer-l;
        list-style: decimal;
      }
    }

    li {
      padding-bottom: 4px;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    color: $blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    color: $black;
  }

  h1 {
    @include font-size--base-4();

    margin-bottom: $spacer-s;
  }

  h2 {
    @include font-size--base-3();

    margin-bottom: $spacer-xs;
  }

  h3 {
    @include font-size--base-2();

    margin-bottom: $spacer-xxs;
  }

  h4,
  h5,
  h6 {
    @include font-size--base-1();

    margin-bottom: 0;
  }

  table {
    display: block;
    max-width: 100%;
    height: auto !important;
    margin-bottom: $spacer-l;
    border: 0;
    color: $grey-2;
    border-collapse: collapse;
    overflow-x: auto;
    overflow-y: hidden;
    word-break: normal;
  }

  th {
    border: 1px solid $grey-4;
    border-bottom: 2px solid $grey-4;
    color: $black;
    font-weight: inherit;
  }

  td {
    padding: $spacer-s;
    border: 1px solid $grey-4;
    color: $grey-1;
    font-family: $font-family-book;
  }
}

@mixin ds-content-attachments($font-size, $margin) {
  &__attachments {
    font-size: $font-size;

    hr {
      margin-block-start: 0;
      margin-block-end: 12px;
    }

    &-title {
      color: $grey-2;
      font-weight: $font-medium;
    }
  }

  &__attachment {
    margin-bottom: $margin;
    margin-left: $margin;

    a {
      color: $blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
/* stylelint-enable selector-max-type */
