.#{$prefix}-blog-post-comment-form {
  margin-bottom: $spacer-xl;
  padding: $spacer-m;
  border-top: 0;
  background-color: $white;

  @media screen and (max-width: $screen-xs-max) {
    margin: $spacer-m calc(#{$spacer-s} * -1) $spacer-xl;
  }

  &__login-link {
    font-size: inherit;
  }
}
