@import "~sap-global-ds/src/core/core";
@import "src/colors";

.#{$prefix}-tag-page {
  &__wrapper {
    flex: 1 0 auto;
    margin-top: $spacer-xl;

    @media only screen and (max-width: $screen-md-max) {
      margin-top: $spacer-l;
    }

    @media only screen and (max-width: $screen-xs-max) {
      margin-top: $spacer-m;
    }
  }

  &__filter-results {
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer-xl;
    border-top: 1px solid $grey-4;
    font-size: $font-size-base;
    font-weight: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;

    &__results {
      display: inline-block;
      padding-top: .25rem;
      color: $grey-3;
      font-family: $font-family-regular;
    }
  }

  &__items {
    padding: 0;
    list-style: none;
  }

  &__item {
    padding: $spacer-m 0;
    border-top: 1px solid $grey-4;

    &:first-of-type {
      margin-top: - $spacer-s;
      padding-top: 0;
      border-top: none;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .#{$prefix}-sidebar {
    .#{$prefix}-filter {
      margin-bottom: $spacer-l;
    }
  }

  .#{$prefix}-pagination {
    margin: $spacer-xxl auto;

    &__grid {
      .#{$prefix}-input, button {
        @media only screen and (max-width: $screen-xs-min) {
          width: 2.3rem;
        }
      }

      @media only screen and (max-width: $screen-sm-max) {
        .#{$prefix}-input {
          width: 3rem;
          margin: 0 .3rem;
        }
      }
    }
  }
  .#{$prefix}-tag-page-loading-indicator__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: $spacer-xl;
  }
}

.#{$prefix}-karma-credits {
  &__container {
    position: relative;
  }

  &-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 1.5rem;
    margin-right: 2.4rem;
    color: $white;
    font-size: .75rem;

    .#{$prefix}-link--white {
      color: $white;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        color: $blue;

        > * {
          color: $blue;
        }
      }
    }
  }
}
