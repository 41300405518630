@import "~sap-global-ds/src/core/core";
@import "src/colors";
@import "src/docs/components/action-bar/mixins";
@import "src/docs/utils/mixins";
@import "src/docs/identity/typography";

.#{$prefix}-answer {
  $_answer-selector: & !global;

  @include ds-content-attachments($font-size-base, $spacer-s);

  border: 1px solid $grey-5;
  background-color: $white;

  &__counter {
    $_answer__counter-selector: & !global;

    @include heading-small;

    padding-top: $spacer-s;
    padding-bottom: $spacer-m;
    border-bottom: 1px solid $grey-3;
  }

  &__sorting-container {
    @include fine-print;

    margin-top: $spacer-xs;
    margin-bottom: $spacer-l;
    text-align: right;
  }

  &__sorting-list {
    display: inline-block;
  }

  &__sorting-list-item {
    display: inline-block;

    &::after {
      margin: 0 $spacer-xxs;
      content: "|";
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__sorting-option {
    @include fine-print;

    padding: $spacer-xxs $spacer-xxs;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &--selected {
      background-color: #eaeaea;
    }
  }

  &__sorting-title {
    margin-right: $spacer-xxs;
  }

  &__pagination {
    margin-top: $spacer-m;
  }

  &__answer-container {
    padding: $spacer-m $spacer-m 0;
  }

  + .#{$prefix}-answer {
    margin-top: $spacer-m;
  }

  &__hero {
    $_answer__hero-selector: & !global;

    position: relative;
  }

  &__heading {
    $_answer__heading-selector: & !global;

    display: flex;
    padding-top: $spacer-s;
  }

  &__heading-right {
    display: flex;
    flex-direction: column;
  }

  &__author {
    .#{$prefix}-user__name {
      @include heading-xsmall;

      color: $grey-1;
    }
  }

  &__content {
    $_answer__content-selector: & !global;

    @include ds-editor-content; // SOC-12770 follow up: remove mixin
    @include body;

    //height of the vote buttons
    min-height: calc(60px + #{$spacer-s});
    margin-bottom: $spacer-m;
    margin-left: $spacer-xl;
    color: $grey-1;
  }

  &__posted {
    @include fine-print;

    color: $grey-2;
  }

  &__vote {
    $_answer__vote-selector: & !global;

    position: absolute;
    top: 58px; // height of the gravatar
    margin-right: $spacer-m;
  }

  &__gravatar {
    width: 40px;
    height: 40px;
    margin-right: $spacer-s;
    border-radius: 100%;

    @media only screen and (max-width: $screen-sm-max) {
      width: 30px;
      height: 30px;
    }

    @media only screen and (max-width: $screen-xs-max) {
      margin-right: $spacer-m;
    }
  }

  &__action-bar {
    @include ds-content-action-bar-colors($grey-2, $blue);

    margin-bottom: $spacer-s;
  }

  &__right {
    $_answer__right-selector: & !global;

    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    min-width: 0;
  }

  &__comments-container {
    $_answer__comments-container-selector: & !global;

    margin: 0 $spacer-m $spacer-m;
    padding-top: $spacer-s;
    border-top: 1px solid $grey-3;

    .#{$prefix}-comments-list__comments-counter {
      @include font-size--base-0;

      margin-bottom: 0;
      padding-bottom: 0;
    }

    .#{$prefix}-icon--comments {
      margin-right: $spacer-xs;

      &::before {
        font-size: 1rem;
      }
    }
  }

  &__comments-container--empty {
    border-top: none;
  }

  &__status {
    display: inline-flex;
    width: 100%;
    margin-bottom: $spacer-s;
  }

  &__status-text {
    @include heading-xsmall;
  }

  &__comment-form {
    $_answer__comment-form-selector: & !global;

    margin: 0 $spacer-m $spacer-m;
  }

  &--accepted {
    $ds-answer-accepted-background: #f3f9f3;
    $ds-answer-accepted-color: #127823;
    $ds-icon-accepted: "data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ctitle%3EArtboard%3C%2Ftitle%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Ccircle%20fill%3D%22%2393C939%22%20cx%3D%229.022%22%20cy%3D%229%22%20r%3D%229%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M6.976%2010.717L5.478%209.22%203.98%2010.716l2.247%202.246.75.748%207.486-7.486-1.498-1.497-5.99%205.99z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fg%3E%0A%3C%2Fsvg%3E";
    .#{$prefix}-answer__answer-container {
      border-top: 2px solid $ds-answer-accepted-color;
      background-color: $ds-answer-accepted-background;
      color: $ds-answer-accepted-color;
    }

    .#{$prefix}-answer__accepted-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: $spacer-xs;
      background-image: url($ds-icon-accepted);
      background-repeat: no-repeat;
      background-size: 16px;
    }

    .#{$prefix}-answer__status-text {
      color: $ds-answer-accepted-color;
    }
  }

  &--alerted {
    $ds-answer-alerted-background: $gold--lighter;
    $ds-answer-alerted-color: $gold--dark3;
    .#{$prefix}-answer__answer-container {
      border-top: 2px solid $ds-answer-alerted-color;
      background-color: $ds-answer-alerted-background;
      color: $ds-answer-alerted-color;
    }
    .#{$prefix}-answer__status-text {
      color: $ds-answer-alerted-color;
    }
  }
}

@at-root {
  @include ds-breakpoint(mobile) {
    #{$_answer-selector} {
      + .#{$prefix}-answer {
        margin-top: $spacer-l;
      }
    }

    #{$_answer__hero-selector} {
      display: flex;
    }

    #{$_answer__heading-selector} {
      padding-top: 0;
    }

    #{$_answer__vote-selector} {
      position: relative;
      top: 0;
    }

    #{$_answer__content-selector} {
      min-height: 1px;
      margin-left: 0;
    }

    #{$_answer__comments-container-selector},
    #{$_answer__comment-form-selector} {
      margin-left: calc(#{$spacer-m} + 50px); //+width of vote container
    }
  }

  @include ds-breakpoint(tablets) {
    #{$_answer__counter-selector} {
      padding-bottom: $spacer-s;
    }
    #{$_answer__comment-form-selector} {
      padding-left: $spacer-l;
    }
  }
}
