@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";

$dark-facebook-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.4%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20x%3D%22-0.9%22%20fill%3D%22%233c3c3c%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0A%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M27.9%2C51.9V34.6H22v-6.9h5.9v-5.4c0-5.9%2C3.7-9.1%2C8.9-9.1c2.5%2C0%2C4.7%2C0.2%2C5.2%2C0.3v6.2h-3.7%0A%09c-2.9%2C0-3.5%2C1.5-3.5%2C3.2v4.7h6.6l-0.9%2C6.9h-5.7v17.3%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$dark-twitter-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.4%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%233c3c3c%22%20width%3D%2264%22%20height%3D%2264%22%2F%3E%0A%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M49.9%2C24.8c0%2C0.3%2C0%2C0.7%2C0%2C1c0%2C10.8-8.2%2C23.2-23.2%2C23.2c-4.6%2C0-8.9-1.3-12.6-3.7c0.7%2C0.1%2C1.3%2C0.1%2C2%2C0.1%0A%09c3.8%2C0%2C7.3-1.3%2C10.1-3.5c-3.6-0.1-6.6-2.4-7.6-5.7c0.5%2C0.1%2C1%2C0.1%2C1.6%2C0.1c0.7%2C0%2C1.5-0.1%2C2.2-0.3c-3.8-0.7-6.6-3.9-6.6-7.9v-0.1%0A%09c1%2C0.6%2C2.4%2C1%2C3.7%2C1c-2.2-1.5-3.7-3.9-3.7-6.8c0-1.5%2C0.4-2.9%2C1.1-4.1c4%2C5%2C10%2C8.2%2C16.9%2C8.6c-0.1-0.6-0.2-1.2-0.2-1.8%0A%09c0-4.5%2C3.7-8.2%2C8.2-8.2c2.4%2C0%2C4.5%2C1%2C5.9%2C2.6c1.8-0.3%2C3.6-1%2C5.2-2c-0.6%2C1.9-1.9%2C3.5-3.6%2C4.5c1.7-0.2%2C3.2-0.6%2C4.7-1.2%0A%09C52.9%2C22.2%2C51.5%2C23.7%2C49.9%2C24.8L49.9%2C24.8z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$dark-linkedin-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%233c3c3c%22%20x%3D%220.1%22%20width%3D%2263.9%22%20height%3D%2264%22%2F%3E%0A%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M23.8%2C46.2h-6.7V24.5h6.7C23.8%2C24.5%2C23.8%2C46.2%2C23.8%2C46.2z%20M20.4%2C21.5c-2.1%2C0-3.9-1.7-3.9-4s1.7-3.9%2C3.9-3.9s3.9%2C1.7%2C3.9%2C3.9%0A%09S22.6%2C21.5%2C20.4%2C21.5z%20M48.9%2C46.2h-6.7V35.6c0-2.5-0.1-5.7-3.5-5.7c-3.5%2C0-4.1%2C2.8-4.1%2C5.5v10.8H28V24.5h6.4v2.9h0.1%0A%09c0.9-1.7%2C3.1-3.5%2C6.4-3.5c6.8%2C0%2C8.1%2C4.5%2C8.1%2C10.3L48.9%2C46.2L48.9%2C46.2L48.9%2C46.2z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$dark-youtube-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%233c3c3c%22%20%20x%3D%220%22%20y%3D%22-0.1%22%20width%3D%2264.2%22%20height%3D%2264.1%22%2F%3E%0A%3Cpath%20%20fill%3D%22%23ffffff%22%20d%3D%22M50.5%2C23c-0.4-1.6-1.7-2.9-3.3-3.3c-2.9-0.8-14.4-0.8-14.4-0.8s-11.5%2C0-14.4%2C0.8c-1.6%2C0.4-2.8%2C1.7-3.3%2C3.3%0A%09c-0.8%2C2.9-0.8%2C8.9-0.8%2C8.9s0%2C6%2C0.8%2C8.9c0.4%2C1.6%2C1.7%2C2.8%2C3.3%2C3.2c2.9%2C0.8%2C14.4%2C0.8%2C14.4%2C0.8s11.5%2C0%2C14.4-0.8c1.6-0.4%2C2.8-1.6%2C3.3-3.2%0A%09c0.8-2.9%2C0.8-8.9%2C0.8-8.9S51.3%2C25.9%2C50.5%2C23z%20M29.1%2C37.4v-11l9.6%2C5.5L29.1%2C37.4L29.1%2C37.4z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$dark-instagram-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%233c3c3c%22%20x%3D%22-0.9%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0A%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M32.2%2C22.9c-4.7%2C0-8.5%2C3.8-8.5%2C8.5s3.7%2C8.6%2C8.5%2C8.6s8.5-3.8%2C8.5-8.6S36.9%2C22.9%2C32.2%2C22.9z%20M32.2%2C36.9c-3%2C0-5.5-2.4-5.5-5.5%0A%09s2.4-5.5%2C5.5-5.5c3.1%2C0%2C5.5%2C2.4%2C5.5%2C5.5S35.2%2C36.9%2C32.2%2C36.9L32.2%2C36.9z%20M43.1%2C22.6c0%2C1.2-0.9%2C2-2%2C2c-1.2%2C0-2-0.9-2-2%0A%09c0-1.1%2C0.9-2%2C2-2C42.2%2C20.6%2C43.1%2C21.5%2C43.1%2C22.6z%20M48.7%2C24.6c-0.2-2.7-0.7-5-2.7-7c-2-2-4.3-2.5-7-2.7c-2.8-0.2-10.9-0.2-13.6%2C0%0A%09c-2.7%2C0.2-5%2C0.7-7%2C2.7c-2%2C2-2.5%2C4.3-2.7%2C7c-0.2%2C2.8-0.2%2C10.9%2C0%2C13.6c0.2%2C2.7%2C0.7%2C5%2C2.7%2C7s4.3%2C2.5%2C7%2C2.7c2.8%2C0.2%2C10.9%2C0.2%2C13.6%2C0%0A%09c2.7-0.2%2C5-0.7%2C7-2.7s2.5-4.3%2C2.7-7C48.8%2C35.5%2C48.8%2C27.4%2C48.7%2C24.6L48.7%2C24.6z%20M45.1%2C41.2c-0.5%2C1.4-1.7%2C2.6-3.2%2C3.2%0A%09c-2.2%2C0.9-7.3%2C0.6-9.8%2C0.6c-2.4%2C0-7.6%2C0.2-9.8-0.6c-1.4-0.5-2.6-1.7-3.2-3.2c-0.9-2.2-0.6-7.3-0.6-9.8s-0.2-7.6%2C0.6-9.8%0A%09c0.5-1.4%2C1.7-2.6%2C3.2-3.2c2.2-0.9%2C7.3-0.6%2C9.8-0.6c2.4%2C0%2C7.6-0.2%2C9.8%2C0.6c1.4%2C0.5%2C2.6%2C1.7%2C3.2%2C3.2c0.9%2C2.2%2C0.6%2C7.3%2C0.6%2C9.8%0A%09S46%2C39%2C45.1%2C41.2z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$dark-slideshare-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Crect%20fill%3D%22%233c3c3c%22%20x%3D%22-0.9%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0D%0A%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M32%2C29c0%2C2.5-2.2%2C4.6-5%2C4.6c-2.7%2C0-5-2.1-5-4.6c0-2.6%2C2.2-4.6%2C5-4.6S32%2C26.4%2C32%2C29z%20M52.5%2C33%0D%0A%09c-1.5%2C1.8-4.3%2C4-8.5%2C5.8c4.5%2C15.4-11%2C17.9-10.8%2C10c0%2C0.1%2C0-4.2%2C0-7.5c-0.3-0.1-0.7-0.2-1.1-0.3c0%2C3.3%2C0%2C7.9%2C0%2C7.8%0D%0A%09c0.3%2C7.9-15.3%2C5.4-10.8-10c-4.3-1.7-7.1-4-8.5-5.8c-0.7-1.1%2C0.1-2.3%2C1.3-1.4c0.2%2C0.1%2C0.3%2C0.2%2C0.5%2C0.3V16c0-2.2%2C1.7-4%2C3.7-4H47%0D%0A%09c2%2C0%2C3.7%2C1.8%2C3.7%2C4v15.9l0.5-0.3C52.4%2C30.7%2C53.2%2C31.9%2C52.5%2C33L52.5%2C33z%20M48.7%2C17.8c0-2.6-0.8-3.7-3.3-3.7H19.9%0D%0A%09c-2.5%2C0-3.3%2C0.9-3.3%2C3.7v15.5c5.4%2C2.8%2C10.1%2C2.3%2C12.7%2C2.3c1.1%2C0%2C1.8%2C0.2%2C2.2%2C0.6c0.1%2C0.1%2C0.8%2C0.8%2C1.6%2C1.4c0.1-1.3%2C0.8-2.1%2C2.7-2%0D%0A%09c2.6%2C0.1%2C7.4%2C0.6%2C12.9-2.4L48.7%2C17.8L48.7%2C17.8z%20M38.6%2C24.4c-2.7%2C0-5%2C2.1-5%2C4.6c0%2C2.5%2C2.2%2C4.6%2C5%2C4.6c2.8%2C0%2C5-2.1%2C5-4.6%0D%0A%09C43.6%2C26.4%2C41.4%2C24.4%2C38.6%2C24.4L38.6%2C24.4z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A";
$dark-email-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%233c3c3c%22%20%20x%3D%22-0.9%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0A%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M48.2%2C18.1H16.5c-2%2C0-3.7%2C1.7-3.7%2C3.7v22c0%2C2%2C1.7%2C3.7%2C3.7%2C3.7h31.8c2%2C0%2C3.7-1.7%2C3.7-3.7v-22C51.9%2C19.8%2C50.2%2C18.1%2C48.2%2C18.1z%0A%09%20M48.2%2C21.8v3.1c-1.8%2C1.4-4.4%2C3.6-10.3%2C8.1c-1.2%2C1-3.8%2C3.4-5.5%2C3.4c-1.8%2C0-4.3-2.4-5.6-3.4c-5.8-4.6-8.6-6.7-10.3-8.1v-3.1H48.2z%0A%09%20M16.5%2C43.8V29.6c1.8%2C1.4%2C4.2%2C3.3%2C8%2C6.4c1.7%2C1.3%2C4.6%2C4.2%2C7.8%2C4.2s6.2-2.9%2C7.8-4.2c3.8-3%2C6.3-4.9%2C8-6.4v14.2H16.5L16.5%2C43.8z%22%2F%3E%0A%3C%2Fsvg%3E%0A";

$light-facebook-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.4%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20x%3D%22-0.9%22%20fill%3D%22%23ffffff%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0A%3Cpath%20fill%3D%22%233c3c3c%22%20d%3D%22M27.9%2C51.9V34.6H22v-6.9h5.9v-5.4c0-5.9%2C3.7-9.1%2C8.9-9.1c2.5%2C0%2C4.7%2C0.2%2C5.2%2C0.3v6.2h-3.7%0A%09c-2.9%2C0-3.5%2C1.5-3.5%2C3.2v4.7h6.6l-0.9%2C6.9h-5.7v17.3%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$light-twitter-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.4%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%23ffffff%22%20width%3D%2264%22%20height%3D%2264%22%2F%3E%0A%3Cpath%20fill%3D%22%233c3c3c%22%20d%3D%22M49.9%2C24.8c0%2C0.3%2C0%2C0.7%2C0%2C1c0%2C10.8-8.2%2C23.2-23.2%2C23.2c-4.6%2C0-8.9-1.3-12.6-3.7c0.7%2C0.1%2C1.3%2C0.1%2C2%2C0.1%0A%09c3.8%2C0%2C7.3-1.3%2C10.1-3.5c-3.6-0.1-6.6-2.4-7.6-5.7c0.5%2C0.1%2C1%2C0.1%2C1.6%2C0.1c0.7%2C0%2C1.5-0.1%2C2.2-0.3c-3.8-0.7-6.6-3.9-6.6-7.9v-0.1%0A%09c1%2C0.6%2C2.4%2C1%2C3.7%2C1c-2.2-1.5-3.7-3.9-3.7-6.8c0-1.5%2C0.4-2.9%2C1.1-4.1c4%2C5%2C10%2C8.2%2C16.9%2C8.6c-0.1-0.6-0.2-1.2-0.2-1.8%0A%09c0-4.5%2C3.7-8.2%2C8.2-8.2c2.4%2C0%2C4.5%2C1%2C5.9%2C2.6c1.8-0.3%2C3.6-1%2C5.2-2c-0.6%2C1.9-1.9%2C3.5-3.6%2C4.5c1.7-0.2%2C3.2-0.6%2C4.7-1.2%0A%09C52.9%2C22.2%2C51.5%2C23.7%2C49.9%2C24.8L49.9%2C24.8z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$light-linkedin-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%23ffffff%22%20x%3D%220.1%22%20width%3D%2263.9%22%20height%3D%2264%22%2F%3E%0A%3Cpath%20fill%3D%22%233c3c3c%22%20d%3D%22M23.8%2C46.2h-6.7V24.5h6.7C23.8%2C24.5%2C23.8%2C46.2%2C23.8%2C46.2z%20M20.4%2C21.5c-2.1%2C0-3.9-1.7-3.9-4s1.7-3.9%2C3.9-3.9s3.9%2C1.7%2C3.9%2C3.9%0A%09S22.6%2C21.5%2C20.4%2C21.5z%20M48.9%2C46.2h-6.7V35.6c0-2.5-0.1-5.7-3.5-5.7c-3.5%2C0-4.1%2C2.8-4.1%2C5.5v10.8H28V24.5h6.4v2.9h0.1%0A%09c0.9-1.7%2C3.1-3.5%2C6.4-3.5c6.8%2C0%2C8.1%2C4.5%2C8.1%2C10.3L48.9%2C46.2L48.9%2C46.2L48.9%2C46.2z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$light-youtube-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%23ffffff%22%20%20x%3D%220%22%20y%3D%22-0.1%22%20width%3D%2264.2%22%20height%3D%2264.1%22%2F%3E%0A%3Cpath%20%20fill%3D%22%233c3c3c%22%20d%3D%22M50.5%2C23c-0.4-1.6-1.7-2.9-3.3-3.3c-2.9-0.8-14.4-0.8-14.4-0.8s-11.5%2C0-14.4%2C0.8c-1.6%2C0.4-2.8%2C1.7-3.3%2C3.3%0A%09c-0.8%2C2.9-0.8%2C8.9-0.8%2C8.9s0%2C6%2C0.8%2C8.9c0.4%2C1.6%2C1.7%2C2.8%2C3.3%2C3.2c2.9%2C0.8%2C14.4%2C0.8%2C14.4%2C0.8s11.5%2C0%2C14.4-0.8c1.6-0.4%2C2.8-1.6%2C3.3-3.2%0A%09c0.8-2.9%2C0.8-8.9%2C0.8-8.9S51.3%2C25.9%2C50.5%2C23z%20M29.1%2C37.4v-11l9.6%2C5.5L29.1%2C37.4L29.1%2C37.4z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$light-instagram-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%23ffffff%22%20x%3D%22-0.9%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0A%3Cpath%20fill%3D%22%233c3c3c%22%20d%3D%22M32.2%2C22.9c-4.7%2C0-8.5%2C3.8-8.5%2C8.5s3.7%2C8.6%2C8.5%2C8.6s8.5-3.8%2C8.5-8.6S36.9%2C22.9%2C32.2%2C22.9z%20M32.2%2C36.9c-3%2C0-5.5-2.4-5.5-5.5%0A%09s2.4-5.5%2C5.5-5.5c3.1%2C0%2C5.5%2C2.4%2C5.5%2C5.5S35.2%2C36.9%2C32.2%2C36.9L32.2%2C36.9z%20M43.1%2C22.6c0%2C1.2-0.9%2C2-2%2C2c-1.2%2C0-2-0.9-2-2%0A%09c0-1.1%2C0.9-2%2C2-2C42.2%2C20.6%2C43.1%2C21.5%2C43.1%2C22.6z%20M48.7%2C24.6c-0.2-2.7-0.7-5-2.7-7c-2-2-4.3-2.5-7-2.7c-2.8-0.2-10.9-0.2-13.6%2C0%0A%09c-2.7%2C0.2-5%2C0.7-7%2C2.7c-2%2C2-2.5%2C4.3-2.7%2C7c-0.2%2C2.8-0.2%2C10.9%2C0%2C13.6c0.2%2C2.7%2C0.7%2C5%2C2.7%2C7s4.3%2C2.5%2C7%2C2.7c2.8%2C0.2%2C10.9%2C0.2%2C13.6%2C0%0A%09c2.7-0.2%2C5-0.7%2C7-2.7s2.5-4.3%2C2.7-7C48.8%2C35.5%2C48.8%2C27.4%2C48.7%2C24.6L48.7%2C24.6z%20M45.1%2C41.2c-0.5%2C1.4-1.7%2C2.6-3.2%2C3.2%0A%09c-2.2%2C0.9-7.3%2C0.6-9.8%2C0.6c-2.4%2C0-7.6%2C0.2-9.8-0.6c-1.4-0.5-2.6-1.7-3.2-3.2c-0.9-2.2-0.6-7.3-0.6-9.8s-0.2-7.6%2C0.6-9.8%0A%09c0.5-1.4%2C1.7-2.6%2C3.2-3.2c2.2-0.9%2C7.3-0.6%2C9.8-0.6c2.4%2C0%2C7.6-0.2%2C9.8%2C0.6c1.4%2C0.5%2C2.6%2C1.7%2C3.2%2C3.2c0.9%2C2.2%2C0.6%2C7.3%2C0.6%2C9.8%0A%09S46%2C39%2C45.1%2C41.2z%22%2F%3E%0A%3C%2Fsvg%3E%0A";
$light-slideshare-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Crect%20fill%3D%22%23ffffff%22%20x%3D%22-0.9%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0D%0A%3Cpath%20fill%3D%22%233c3c3c%22%20d%3D%22M32%2C29c0%2C2.5-2.2%2C4.6-5%2C4.6c-2.7%2C0-5-2.1-5-4.6c0-2.6%2C2.2-4.6%2C5-4.6S32%2C26.4%2C32%2C29z%20M52.5%2C33%0D%0A%09c-1.5%2C1.8-4.3%2C4-8.5%2C5.8c4.5%2C15.4-11%2C17.9-10.8%2C10c0%2C0.1%2C0-4.2%2C0-7.5c-0.3-0.1-0.7-0.2-1.1-0.3c0%2C3.3%2C0%2C7.9%2C0%2C7.8%0D%0A%09c0.3%2C7.9-15.3%2C5.4-10.8-10c-4.3-1.7-7.1-4-8.5-5.8c-0.7-1.1%2C0.1-2.3%2C1.3-1.4c0.2%2C0.1%2C0.3%2C0.2%2C0.5%2C0.3V16c0-2.2%2C1.7-4%2C3.7-4H47%0D%0A%09c2%2C0%2C3.7%2C1.8%2C3.7%2C4v15.9l0.5-0.3C52.4%2C30.7%2C53.2%2C31.9%2C52.5%2C33L52.5%2C33z%20M48.7%2C17.8c0-2.6-0.8-3.7-3.3-3.7H19.9%0D%0A%09c-2.5%2C0-3.3%2C0.9-3.3%2C3.7v15.5c5.4%2C2.8%2C10.1%2C2.3%2C12.7%2C2.3c1.1%2C0%2C1.8%2C0.2%2C2.2%2C0.6c0.1%2C0.1%2C0.8%2C0.8%2C1.6%2C1.4c0.1-1.3%2C0.8-2.1%2C2.7-2%0D%0A%09c2.6%2C0.1%2C7.4%2C0.6%2C12.9-2.4L48.7%2C17.8L48.7%2C17.8z%20M38.6%2C24.4c-2.7%2C0-5%2C2.1-5%2C4.6c0%2C2.5%2C2.2%2C4.6%2C5%2C4.6c2.8%2C0%2C5-2.1%2C5-4.6%0D%0A%09C43.6%2C26.4%2C41.4%2C24.4%2C38.6%2C24.4L38.6%2C24.4z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A";
$light-email-icon: "data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2023.0.6%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2064%2064%22%20style%3D%22enable-background%3Anew%200%200%2064%2064%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20fill%3D%22%23ffffff%22%20%20x%3D%22-0.9%22%20width%3D%2264.9%22%20height%3D%2265%22%2F%3E%0A%3Cpath%20fill%3D%22%233c3c3c%22%20d%3D%22M48.2%2C18.1H16.5c-2%2C0-3.7%2C1.7-3.7%2C3.7v22c0%2C2%2C1.7%2C3.7%2C3.7%2C3.7h31.8c2%2C0%2C3.7-1.7%2C3.7-3.7v-22C51.9%2C19.8%2C50.2%2C18.1%2C48.2%2C18.1z%0A%09%20M48.2%2C21.8v3.1c-1.8%2C1.4-4.4%2C3.6-10.3%2C8.1c-1.2%2C1-3.8%2C3.4-5.5%2C3.4c-1.8%2C0-4.3-2.4-5.6-3.4c-5.8-4.6-8.6-6.7-10.3-8.1v-3.1H48.2z%0A%09%20M16.5%2C43.8V29.6c1.8%2C1.4%2C4.2%2C3.3%2C8%2C6.4c1.7%2C1.3%2C4.6%2C4.2%2C7.8%2C4.2s6.2-2.9%2C7.8-4.2c3.8-3%2C6.3-4.9%2C8-6.4v14.2H16.5L16.5%2C43.8z%22%2F%3E%0A%3C%2Fsvg%3E%0A";

.#{$prefix}-social-link {
  margin-left: 0;
}

.#{$prefix}-social-icon {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

// dark icons
.#{$prefix}-social-icon--dark-facebook {
  background-image: url($dark-facebook-icon);
}

.#{$prefix}-social-icon--dark-twitter {
  background-image: url($dark-twitter-icon);
}

.#{$prefix}-social-icon--dark-linkedin {
  background-image: url($dark-linkedin-icon);
}

.#{$prefix}-social-icon--dark-instagram {
  background-image: url($dark-instagram-icon);
}

.#{$prefix}-social-icon--dark-email {
  background-image: url($dark-email-icon);
}

.#{$prefix}-social-icon--dark-youtube {
  background-image: url($dark-youtube-icon);
}

.#{$prefix}-social-icon--dark-slideshare {
  background-image: url($dark-slideshare-icon);
}

// light icons
.#{$prefix}-social-icon--light-facebook {
  background-image: url($light-facebook-icon);
}

.#{$prefix}-social-icon--light-twitter {
  background-image: url($light-twitter-icon);
}

.#{$prefix}-social-icon--light-linkedin {
  background-image: url($light-linkedin-icon);
}

.#{$prefix}-social-icon--light-instagram {
  background-image: url($light-instagram-icon);
}

.#{$prefix}-social-icon--light-email {
  background-image: url($light-email-icon);
}

.#{$prefix}-social-icon--light-youtube {
  background-image: url($light-youtube-icon);
}

.#{$prefix}-social-icon--light-slideshare {
  background-image: url($light-slideshare-icon);
}
