@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "~sap-global-ds/src/core/core";
@import "~react-image-crop/lib/ReactCrop";

.ds-fileUploadPreviewModal {
  display: flex;

  /* stylelint-disable selector-max-type */
  // have to has tag selector, because not able to assign class to crop library element
  &__image {
    img {
      /* stylelint-disable-next-line function-name-case */
      max-width: MIN(500px, 100%);
      max-height: 500px;
    }
  }
  /* stylelint-enable selector-max-type */
  &__image-container {
    display: inline-flex;
    margin: auto;
    padding: $spacer-xxs;
    border: 1px solid black;

    &--loading {
      display: none !important;
    }
  }

  &__error {
    margin-top: $spacer-m;
  }
}
