@import "~sap-global-ds/src/core/core";

$font-size-base: .875rem;
$font-book: 400;
$font-light: 400;
$control-margin-bottom: 0;
$control-placeholder-scale: 1;
$control-placeholder-color: #727272; //grey-2
$icon-font-path: "../../assets/fonts/";
$icon-sizes: (
  //font-size, height, width, line-height
  regular:(1rem, $font-size-base, 1rem, $font-size-base),
  big: (1.5rem, 1.5rem, 1.5rem, 1),
  badge: (.5rem, .5rem, .5rem, 1)
);

/* stylelint-disable-next-line */
#page {
  overflow-x: hidden;
}

@import "../../../colors";
@import "~sap-global-ds/src/core/core";

$icon-sap-logo-header-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='92' height='45' viewBox='0 0 92 45'%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='45.483' x2='45.483' y2='45.001'%3E%3Cstop offset='0' stop-color='%2300AEEF'/%3E%3Cstop offset='.212' stop-color='%230097DC'/%3E%3Cstop offset='.519' stop-color='%23007CC5'/%3E%3Cstop offset='.792' stop-color='%23006CB8'/%3E%3Cstop offset='1' stop-color='%230066B3'/%3E%3C/linearGradient%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='url%28%23a%29' d='M0 45h45.974L90.966 0H0v45'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23fff' d='M53.984 9H45l.03 21.13-7.823-21.137H29.45l-6.678 17.653c-.71-4.492-5.354-6.042-9.008-7.203-2.413-.775-4.974-1.915-4.948-3.175.02-1.034 1.37-1.993 4.053-1.85 1.8.097 3.39.242 6.553 1.77l3.11-5.42C19.648 9.3 15.66 8.373 12.39 8.37h-.02c-3.813 0-6.988 1.235-8.956 3.27-1.372 1.42-2.112 3.226-2.142 5.223-.05 2.748.957 4.696 3.073 6.253 1.788 1.31 4.075 2.16 6.09 2.784 2.485.77 4.515 1.44 4.49 2.866-.02.52-.216 1.006-.59 1.398-.62.64-1.57.88-2.885.906-2.537.054-4.417-.345-7.413-2.116L1.27 34.444C4.263 36.146 7.44 37 11.05 37l.812-.006c3.142-.057 5.692-.81 7.718-2.44.116-.093.22-.187.328-.282l-.34 1.752 7.58-.024 1.36-3.482c1.43.488 3.056.758 4.782.758 1.682 0 3.264-.256 4.667-.716l.948 3.44 13.6.013.033-7.938h2.894c6.995 0 11.13-3.56 11.13-9.53C66.56 11.896 62.54 9 53.984 9zM33.29 27.062c-1.045 0-2.025-.182-2.868-.502l2.836-8.955h.055l2.79 8.98c-.84.3-1.797.477-2.814.477zm21.22-5.145h-1.974V14.7h1.975c2.63 0 4.73.876 4.73 3.562-.002 2.78-2.1 3.655-4.73 3.655'/%3E%3Cpath fill='none' d='M0 0h92v45H0z'/%3E%3C/svg%3E";

$ds-header-min-screen: 375px; //break point for mobile menu with

.#{$prefix}-icon--sap-logo {
  width: 64px;
  height: 31px;
  border: 0 none;
  background-image: url($icon-sap-logo-header-svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.#{$prefix}-header {
  height: 64px;
  background-color: $grey-6;

  &__inner {
    $_header__inned-selector: & !global;

    display: flex;
    position: relative;
    flex-flow: row nowrap;
    align-content: baseline;
    align-items: flex-start;
    justify-content: space-between;
    max-width: $screen-lg-min;
    height: 34px;
    height: 100%;
    margin: 0 auto;
    padding: 0 $spacer-s;
    padding: 0 10px;

    &.#{$prefix}-search-active {
      display: flex;
      justify-content: flex-start;
      height: auto;
      overflow: visible;

      .#{$prefix}-main-nav__menu-item {
        display: none;
      }

      .#{$prefix}-header__account {
        display: none;
      }

      .#{$prefix}-main-nav {
        width: 180px;
      }

      .#{$prefix}-header__actions {
        justify-content: center;
        width: 100%;
        margin-left: unset;
      }
    }
  }

  &__logo-wrapper {
    padding: 15px 0 15px 0;
  }

  @include ds-breakpoint(small-desktop) {
    #{$_header__inned-selector} {
      padding: 0;
    }
  }
}

.#{$prefix}-main-nav {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 100%;
  background: transparent;
  box-shadow: none;

  @media screen and (min-width: $screen-md-min) {
    width: 100%;
  }

  .#{$prefix}-mobile-search {
    display: none;
    flex-flow: column nowrap;
    padding: 12px 0;
    color: $grey-1;
  }

  &__menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height:
      calc(
        100% + 2px - 64px
      ); //-64 for search input, +2 for scrolling hack

    padding-left: 0;
    color: $grey-1;
    list-style: none;

    .#{$prefix}-header__home-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 112px;
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid $grey-5;
      color: $grey-1;

      .#{$prefix}-mobile-menu--active & {
        display: none;
        border-top: 1px solid $grey-5;
      }

      &-link {
        padding-left: 4px;
        color: $grey-1;
        font-family: $font-family-regular;
        font-size: 20px;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }

      @media screen and (min-width: $screen-md-min) {
        margin-right: 2%;
      }

      @media screen and (min-width: 1024px) {
        margin-right: 7%;
      }

      @media screen and (min-width: 1120px) {
        margin-right: 10%;
      }
    }

    .#{$prefix}-mobile-menu--active & {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-top: 10px;
      border-top: 1px solid $grey-5;
    }

    @media screen and (min-width: $screen-md-min) {
      display: flex;
      flex-basis: 100%;
      flex-flow: row nowrap;
      align-items: center;
      height: 64px;
      margin: 0;
      padding: 0;
      border: none;
      list-style: none;
    }

    &-item {
      display: none;
      flex-flow: row nowrap;
      align-items: center;
      padding: 20px 0 20px 0;
      border-bottom: 1px solid $grey-5;

      @media screen and (min-width: $screen-md-min) {
        display: flex;
        right: 0;
        flex-flow: row nowrap;
        align-items: center;
        height: 63px;
        margin: 0;
        padding: 4px 16px 0 16px;
        border-bottom: 4px solid transparent;
        color: $grey-1;
      }

      .#{$prefix}-mobile-menu--active & {
        display: flex;
        width: 100%;
      }

      &.#{$prefix}-is-active {
        @media screen and (min-width: $screen-md-min) {
          border-bottom: 4px solid $gold;
        }

        .#{$prefix}-main-nav__menu-item-dropdown-container {
          display: block;
        }
        .#{$prefix}-main-nav__sub-menu {
          display: flex;
          list-style-type: none;
        }
      }

      &-link {
        display: flex;
        position: relative;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        color: $grey-1;
        font-family: $font-family-regular;
        font-size: 15px !important;
        line-height: 20px;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }

        &:active,
        &:visited {
          color: $grey-1;
        }

        @media screen and (min-width: $screen-md-min) {
          display: inline-block;
          justify-content: flex-start;
          line-height: 20px;
          text-decoration: none;
        }
      }

      &-arrow {
        align-self: flex-end;
        justify-self: self-end;

        @media screen and (min-width: $screen-md-min) {
          display: none !important;
        }
      }

      &-dropdown-container {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 1001;

        @media screen and (min-width: $screen-md-min) {
          right: auto;
          bottom: auto;
          overflow: visible;
        }
      }

      &--hidden {
        display: none;
      }
    }
  }

  &.#{$prefix}-mobile-menu--active {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: auto;
    flex-flow: column nowrap;
    width: 311px;
    height: 100%;
    min-height: 100%;
    padding: 0 20px;
    transition: left .3s;
    border: 1px solid $grey-5;
    background: $white;
    font-size: 15px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, .2);
    z-index: 101;

    @media screen and (min-width: $ds-header-min-screen + 1px) {
      width: 311px;
    }
    .#{$prefix}-mobile-search {
      display: block;
    }
  }

  &.#{$prefix}-mobile-menu--hidden {
    @media screen and (max-width: $screen-md-min) {
      display: none;
    }
  }

  &__sub-menu {
    display: none;
    width: 311px;
    padding: 0;
    padding: 20px;
    background: $white;
    color: $grey-1;
    font-size: 15px;
    box-shadow: none;

    @media screen and (min-width: $screen-md-min) {
      flex-flow: column nowrap;
      width: auto;
      min-width: 240px;
      padding: 20px;
      padding-bottom: 12px;
      transform: translateX(-40%);
      box-shadow: 0 20px 20px 0 rgba(0, 0, 0, .2);
      z-index: 1000;
    }

    &.#{$prefix}-is-active {
      display: flex;
      position: relative;
      left: auto;
      flex-flow: column nowrap;
      min-height: calc(100% + 2px);
      z-index: 1000;

      @media screen and (min-width: $screen-md-min) {
        position: absolute;
        top: 64px;
        right: auto;
        bottom: auto;
        left: auto;
        height: auto;
        z-index: 1000;
      }
    }

    @media screen and (min-width: $screen-md-min) {
      display: none;
    }

    &-mobile-title {
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      align-items: left;
      padding: 20px 0 20px 0;
      border-bottom: 1px solid $grey-5;
      list-style: none;

      @media screen and (min-width: $screen-md-min) {
        display: none;
        white-space: nowrap;
      }
    }

    &-title-link {
      align-self: flex-start;
      border: none;
      background-color: transparent;
      color: $grey-1;
      font-family: $font-family-medium;
      font-size: 15px;
      line-height: 17px;
      text-decoration: underline;

      &:active,
      &:visited {
        color: $grey-1;
      }

      &:focus {
        outline: 0;
      }

      .#{$prefix}-main-nav__sub-menu-title-arrow {
        margin-right: 8px;
        float: left;
      }

      @media screen and (min-width: $screen-md-min) {
        padding: 0;
        border-bottom: 0;
        color: $grey-1;
        white-space: nowrap;
      }

      &:visited {
        color: $grey-1;
      }

      &:hover,
      &:active,
      &:focus {
        color: $blue--light3;
        text-decoration: underline;
      }
    }

    &-item {
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      align-items: left;
      padding: 18px 0 18px 0;
      border-bottom: 1px solid $grey-5;
      list-style: none;

      @media screen and (min-width: $screen-md-min) {
        flex-flow: row nowrap;
        align-items: center;
        padding: 8px 0;
        border: 0;
        white-space: nowrap;
      }

      &--overview {
        display: flex;
        position: relative;
        flex-flow: column nowrap;
        align-items: left;
        padding: 20px 20px 20px 0;
        border-bottom: 1px solid $grey-5;
        list-style: none;

        @media screen and (min-width: $screen-md-min) {
          display: none;
        }
      }
    }

    &-link {
      color: $grey-1;
      font-family: $font-family-regular;
      font-size: 15px;
      line-height: 20px;
      text-decoration: none;
      cursor: pointer;

      &:active,
      &:visited {
        color: $grey-1;
      }
      .#{$prefix}-main-nav__sub-menu-title-arrow {
        margin-right: 8px;
        float: left;
      }

      @media screen and (min-width: $screen-md-min) {
        padding: 0;
        color: $grey-1;
        line-height: 1;
      }

      &:visited {
        color: $grey-1;
      }

      &:hover,
      &:active,
      &:focus {
        color: $blue--light3;
        text-decoration: underline;
      }
    }
  }
}

.#{$prefix}-header__actions {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-end;
  height: 64px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  padding-left: 0;
  color: $grey-1;
  list-style: none;

  @media screen and (min-width: $screen-md-min) {
    min-width: 90px;
  }

  &-item {
    display: flex;
    position: static;
    left: auto;
    align-items: center;
    padding: 0 8px;

    .#{$prefix}-search-action {
      cursor: pointer;
    }

    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
      .#{$prefix}-search-action {
        display: none;
      }
    }

    &.#{$prefix}-header--mobile-hidden {
      @media screen and (max-width: $screen-md-min) {
        display: none;
      }
    }
  }

  .#{$prefix}-header__mobile-menu {
    @media screen and (min-width: $screen-md-min) {
      display: none;
    }

    &--active {
      margin-right: 311px;
    }
  }

  .#{$prefix}-header-search-input {
    width: 100%;

    .#{$prefix}-search {
      height: 64px;

      .#{$prefix}-input {
        &__box {
          height: 100%;
        }

        &__control {
          height: 100%;
          padding-right: 40px;
          padding-left: 65px;

          &:hover,
          &:active,
          &:focus {
            border: 1px solid $grey-3;
          }
        }

        &__placeholder {
          left: 59px;
        }
      }

      &__button {
        &-clear {
          right: $spacer-s;
        }

        &-submit {
          left: 0;
          width: 64px;
          margin-left: 1px;
          border-bottom: 4px solid $gold;

          i {
            width: 23px;
            height: 23px;
            background-size: 23px 23px;
          }
        }
      }
    }
  }
}

.#{$prefix}-header-search__wrapper {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  width: 640px;
  margin-right: 20%;
  padding: 0 0;
  background: transparent;
  z-index: 1000;
}

.#{$prefix}-header-search--hidden {
  display: none;
}

.#{$prefix}-header-search__close-button {
  padding-left: $spacer-s;

  &:hover {
    cursor: pointer;
  }
}

.#{$prefix}-header__avatar-image {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 50%;
}

.#{$prefix}-header--scrollable {
  overflow-y: scroll !important;
}

.#{$prefix}-body--noscroll {
  @media screen and (max-width: $screen-md-min) {
    overflow: hidden !important;
  }
}

// Class for pages with id='page'
.#{$prefix}-mobile-menu--active {
  @media screen and (max-width: $screen-md-min) {
    position: relative;
    left: -311px;
    overflow: visible;
  }
}

.#{$prefix}-header__tab-button {
  border: none;
  background-color: transparent;
}

.#{$prefix}-header__tab-button:focus,
.#{$prefix}-tab-button__content:focus {
  outline: none;
}

.#{$prefix}-header__tab-button:focus > .#{$prefix}-tab-button__content {
  outline: 2px solid #1a58ca;
}
